import 'jui/autocomplete';
import 'datePicker';
import $ from 'jquery';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import Layout from '@glu/core/src/layout';
import Dialog from '@glu/dialog';
import policies from 'policies';
import f1 from 'system/utilities/f1';
import LookupCollection from 'common/dynamicPages/collections/lookup';
import MetaModel from 'common/dynamicPages/models/meta';
import entitlements from 'common/dynamicPages/api/entitlements';
import viewHelper from 'common/dynamicPages/api/viewHelper';
import BatchChildGridView from 'common/dynamicPages/views/batchChildGrid';
import BatchChildEntry from 'common/dynamicPages/views/batchChildEntry';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import DuplicateDialog from 'common/dynamicPages/views/duplicateDialog';
import printExportUtil from 'common/util/printExportUtil';
import CheckReview from 'common/dynamicPages/views/checkReview';
import store from 'system/utilities/cache';
import constants from 'common/dynamicPages/api/constants';
import BeneUpdatedApprovalWarning from 'common/dynamicPages/views/beneUpdatedApprovalWarning';
import BeneUpdatedWarning from 'common/dynamicPages/views/beneUpdatedWarning';
import BeneficiaryWidget from 'common/uiWidgets/beneficiariesWidget/beneficiariesWidget';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import errorHandlers from 'system/error/handlers';
import reportConstants from 'app/reports/constants';
import DynamicReportUtil from 'app/reports/views/dynamicReports';
import ConversationView from 'app/payments/views/realTimePayments/rtpConversationView';
import transform from 'common/util/transform';
import scroll from 'common/util/scroll';
import hash from 'common/util/promise-hash';
import PaymentUtil from 'common/util/paymentUtil';
import domUtil from 'common/util/domUtil';
import { moveToTopCheck } from 'common/util/deeplinkUtil';
import datePickerHelper from './componentHelpers/datePicker';
import timeFieldHelper from './componentHelpers/timeField';
import comboboxHelper from './componentHelpers/combobox';
import typeaheadhelper from './componentHelpers/typeahead';
import multiTypeaheadHelper from './componentHelpers/multiTypeahead';
import rcountHelper from './componentHelpers/rcount';
import infoTooltipHelper from './componentHelpers/infoTooltip';
import filterHelper from './componentHelpers/filters';
import gridHelper from './componentHelpers/grid';
import singleLookupHelper from './componentHelpers/singleLookup';
import multiLookupHelper from './componentHelpers/multiLookup';
import amountFormatHelper from './componentHelpers/amountFormat';
import childHelper from './componentHelpers/children';
import widgetHelper from './componentHelpers/widgetHelper';
import template from './metaDrivenForm.hbs';
import templateHelpers from './templateHelpers'; // eslint-disabled

const MetaDrivenForm = Layout.extend({
    template,
    loadingTemplate,
    pageForm: null,

    /*
     * NH-45647
     * The disableInitialViewBinding doesn't exist before, this cause the value
     * on the view reset to its initial value and lost the values resolved from
     * the template helper, as a result, the ComboBoxSelect field displays its
     * code value in view mode instead of descriptive value.
     * Add this attribute and set it to true for view, defaults to false, which
     * keeps the existing behavior for insert and modify as before.
     */
    disableInitialViewBinding: false,

    className() {
        let name = '';
        if (this.model && this.model.jsonData && this.model.jsonData.typeInfo) {
            name = `mdf-${this.model.jsonData.typeInfo.functionCode}-${this.model.jsonData.typeInfo.productCode}-${this.model.jsonData.typeInfo.typeCode}`;
        }

        return name;
    },

    ui: {
        $readOnlyRadio: 'input[type="radio"][readonly]',
        $popovers: '[data-toggle="popover"]',
        $layover: '[data-region="layover"]',
        $amount: 'input[data-type="amount"]',
        $creditAmount: 'input[name="CREDIT_AMOUNT"]',
        $debitAmount: 'input[name="DEBIT_AMOUNT"]',
        $operatorSelect: 'select.field-operator',
        $loadingOverlay: '.mdf-loading-overlay',
    },

    events: {
        'click @ui.$readOnlyRadio': 'handleReadOnlyClick',
        'focus @ui.$amount': 'handleFocusEvent',
        'change @ui.$amount,@ui.$creditAmount,@ui.$debitAmount': 'changeAmount',
        'change @ui.$operatorSelect': 'handleOperatorsOnChange',
    },

    behaviors: {
        ValidationSupplement: {},
        LookupHelperText: {},
    },

    dialogButtons: [{
        text: locale.get('common.save'),
        className: 'btn btn-primary',
        callback: 'save',
    }, {
        text: locale.get('common.cancel'),
        className: 'btn btn-link',
        callback: 'cancel',
    }],

    modalClass: 'modal-xlg',

    initialize(optionsParam) {
        const options = optionsParam;
        // Setting up options with defaults
        this.batchChildGridView = null;
        this.bulkCheckView = options.bulkCheckView || false;
        this.childView = null;
        this.comboBoxData = options.comboBoxData || false;
        this.conditionalFields = options.conditionalFields;
        this.context = options.context;
        this.datePickerOptions = options.datePickerOptions;
        this.dependentTypeAheadFields = options.dependentTypeAheadFields;
        this.formReloadedDriverFieldName = '';
        this.gridApi = options.gridApi;
        this.gridComponentViews = {};
        this.hideButtons = options.hideButtons || false;
        this.initializeOptions = options;
        this.overrides = options.overrides;
        this.pageView = options.pageView;
        this.parentModel = options.parentModel;
        this.parentView = options.parentView;
        this.preFill = options.preFill || {};
        this.reloadingDriverField = '';
        this.typeAheadCollections = [];
        this.typeAheadData = options.typeAheadData || false;
        this.viewType = options.viewType || 'default';

        options.state = options.state || 'insert';
        this.state = options.state;

        this.processingDates = {
            blockedDates: [],
            processingDays: [],
            daysForward: [30],
            cutOffTimes: [],
            daysBack: 0,
            earliestDay: this.datePickerOptions && this.datePickerOptions.earliestDay,

            // Default reports to allow weekends
            allowWeekends: this.context.functionCode === 'RPT',
        };

        // Default buttons for view mode modals
        if (this.viewType === 'modal' && this.state === 'view') {
            this.dialogButtons = [{
                text: locale.get('common.cancel'),
                className: 'btn btn-primary',
                callback: 'cancel',
            }];
        }

        this.isCTX = this.checkIsCTX(this.options.context);

        this.setNotificationData({
            title: this.context.serviceName,
        });

        // TODO: NH-40179 - Try to prevent beneWidget logic from eating the world.
        this.useBeneWidget = this.shouldUseBeneWidget();

        if (options.beneWidget) {
            this.beneWidget = options.beneWidget;
        } else if (this.useBeneWidget) {
            this.beneWidget = this.initBeneWidget();
            if (this.model) {
                this.beneWidget.batchSeqNum = this.model.get('BATCHSEQNUM');
            }
        }

        if (this.shouldUseClearBeneBehavior() && !this.behaviors.ClearBeneficiary) {
            this.behaviors.ClearBeneficiary = {};
        }

        /*
         * NH-45647
         * Set the disableInitialViewBinding during initialize() to true for VIEW to
         * keep the values
         * replaced from the templateHelper.
         */
        this.disableInitialViewBinding = this.state === 'view';
    },

    /**
      * IDT-39535 - Model Reload overlay
      * @param {boolean} toggle
      */
    toggleSpinnerOverlay(toggle) {
        this.ui.$loadingOverlay.toggleClass('hidden', !toggle);
    },

    /**
     * HACK: custom functionality for CTX payment type
     * Should be handled appropriately via MDF config instead of just looking
     * at the type
     *
     * @param {object} context
     * @return {boolean}
     */
    checkIsCTX(context) {
        return !!((context.subType === 'NACHA' && context.serviceName.toUpperCase().indexOf('CORPORATETRADEEXCHANGE') > -1)
            || (context.subType === 'NACHA' && context.actionData && context.actionData.typeCode === 'BDACHCTX'));
    },

    /**
     * Decide if we should use the Beneficiary Widget from Payment Screen Redesign
     * @return {boolean}
     */
    shouldUseBeneWidget() {
        let incTypeCode;
        if (this.model && this.model.get('TYPE')) {
            incTypeCode = this.model.get('TYPE');
        } else if (this.context.serviceNameTypeCode) {
            incTypeCode = this.context.serviceNameTypeCode;
            // HACK: SMB Child Support is breaking
            if (this.context.serviceName === '/batch/ChildSupportPayments') {
                return false;
            }
        }
        if (constants.PAYMENT_TYPES_USING_BENEFICIARY_WIDGET.indexOf(incTypeCode) > -1) {
            return true;
        }

        return false;
    },

    /**
     * Setup the basic Beneficiary Widget
     * @return {View}
     */
    initBeneWidget() {
        // FIXME: Find a better way to choose to instantiate the beneWidget?
        return new BeneficiaryWidget({
            context: this.context,
            state: this.state,
            model: this.model,
            view: this,
            widgetID: 'BENE',
            MDF: MetaDrivenForm,
            childHelper,
        });
    },

    /**
     * Determine if we should add the ClearBeneficiary Behavior during initialize
     * @return {boolean}
     */
    shouldUseClearBeneBehavior() {
        let incTypeCode;
        if (this.model && this.model.get('TYPE')) {
            incTypeCode = this.model.get('TYPE');
        } else if (this.context.serviceNameTypeCode) {
            incTypeCode = this.context.serviceNameTypeCode;
        }

        return constants.PAYMENT_TYPES_USING_CLEARBENE_BEHAVIOR.indexOf(incTypeCode) > -1;
    },

    onRender() {
        const self = this;

        if (this.hasLoadedRequiredData()) {
            this.$el.attr('class', this.className());
            if (this.context.reimburse && this.context.actionMode === 'INSERT') {
                this.setSmbReimburseAttributes(
                    self.model,
                    this.context.actionMode.toLowerCase(),
                );
            } else {
                this.setEntryDescription(this.model);
                this.setCompDiscData(this.model);
            }
            this.runThroughPrefill();
            this.setFormDefaults();
            this.setupFormComponents();
            this.setupHacks();

            // Copy from template should have readonly fields to protect them from changes
            // Some fields need this, even in copy from template, like Extended Remit
            this.setComboTriggers();

            const finishSetup = () => {
                util.defer(util.bind(this.loadPolicies, this));

                // Reenable buttons when user cancels the RSA soft token challenge.
                this.appBus.on('mfa:rsasofttoken:cancel', () => {
                    this.disableButtons(false);
                });

                childHelper.setupChildRegions(this, MetaDrivenForm);

                this.trigger('ui-loaded');

                this.notifyPageLoaded();
            };

            // Wait for MDF Widgets if they exist
            if (this.allWidgets && this.allWidgets.length) {
                const widgetPromises = this.allWidgets.map(widget => widget.isReadyToRender());

                Promise.all(widgetPromises)
                    .catch(() => // Nothing to be done for errors?
                        true)
                    .then(finishSetup);
            } else {
                finishSetup();
            }
        } else {
            this.loadViewRequirements(this.initializeOptions);
        }
    },

    /**
     * sets the ENTRYDESC field to be displayed properly
     * @param {Model} model
     * @param {string} mode
     */
    setSmbReimburseAttributes(model, mode) {
        if (mode === 'view' || mode === 'modify' || mode === 'insert') {
            model.set('ENTRYDESC', model.get('UE_ENTRYDESC'));
        }
    },

    /**
     * The UE_ENTRYDESC field is read back to be displayed properly
     * @param {Model} model
     */
    setEntryDescription(model) {
        if (util.isNullOrUndefined(model)) {
            return;
        }

        const ueEntryDesc = model.get('UE_ENTRYDESC');
        if (ueEntryDesc !== undefined && ueEntryDesc.length > 0) {
            model.set('ENTRYDESC', ueEntryDesc);
        }
    },

    /**
     * The UE_COMPDISCDATA field contains the user entered override for the
     * configured COMPDISCDATA value.
     * @param {Model} model
     */
    setCompDiscData(model) {
        if (util.isNullOrUndefined(model)) {
            return;
        }

        const ueCompDiscData = model.get('UE_COMPDISCDATA');
        if (ueCompDiscData && ueCompDiscData.length > 0) {
            model.set('COMPDISCDATA', ueCompDiscData);
        }
    },

    /**
     * @method onClose
     * @description - method that is invoked when the view is closed.
     * If we are not a batch child view, then unset the helpPage that is used for
     * the global help.
     *
     */
    onClose() {
        if (!this.options.isChild) {
            store.unset('helpPage'); // remove view helppage from cache
        }
        // hide the tab on the drawer if it is present
        const $drawerTab = $('[data-hook="getDrawerTabButton"]');
        if ($drawerTab.length === 1) {
            this.appBus.trigger('dgb:drawer:update', {
                shouldBeOpen: false,
                view: ConversationView,
                showTab: false,
                viewOptions: {
                    model: this.model,
                    allowDetail: false,
                },
            });
        }
    },

    /**
     * Setting up the basic form components
     * @param {jQuery} [$container] - Optional replacement container for special
     * handling (beneficiary)
     */
    setupFormComponents($container) {
        /*
         * HACK - MDF BeneWidget
         * Because beneWidget uses its own view, but we need the special values from
         * the original childView
         * we pass in the container, but several of the nested functions still depend on the
         * $el and $ (find)
         * of the view to locate elements.
         * As a result, we *temporarily* override the jQuery elements on the childView to point
         * to our page
         * and restore the originals at the bottom.
         */

        const old$el = this.$el;

        const old$ = this.$;

        if ($container) {
            this.$el = $container;
            this.$ = $container.find.bind($container);
        }

        comboboxHelper.setupComboboxes(this, undefined, $container);
        typeaheadhelper.setupTypeaheadBoxes(this, undefined, $container);
        multiTypeaheadHelper.setupMultiTypeaheadBoxes(this, undefined, $container);
        rcountHelper.setupRCountBoxes(this, undefined, $container);
        gridHelper.setupGrids(this, $container);
        // for ui widgets
        widgetHelper.setupWidgets(this, undefined, $container);
        infoTooltipHelper.setupInfoTooltips(this);

        if (this.context.serviceName.indexOf('advanceFilter') > -1) {
            filterHelper.setupAdvancedFilter(this);
        }

        // Setup image viewer if the region exists
        if (this.imageViewer) {
            this.imageViewer.show(new CheckReview({
                model: this.model,
                bulkCheckView: this.bulkCheckView,
            }));
        }

        if (this.state !== 'view') {
            /* for any scheduled payment type, we dont restrict forward days on calendar
             * Scheduled is entrymethod =1 and createdfrom = 2
             */
            if (this.model.get('ENTRYMETHOD') === constants.ENTRY_METHOD.TEMPLATE && this.model.get('CREATEDFROM') === '2') {
                this.processingDates.daysForward[0] = 370;
            }
            datePickerHelper.setupDatePickers(this, $container);
            timeFieldHelper.setupTimeFields(this, $container);
            singleLookupHelper.setupSingleLookups(this, $container);
            multiLookupHelper.setupMultiLookups(this, $container);
            amountFormatHelper.setupAmountFormats(this, $container);
        }

        if ($container) {
            this.$el = old$el;
            this.$ = old$;
        }

        // set up listener to scroll to errors when required
        this.listenTo(this.model, 'invalid', (data) => {
            if (!data || !data.fieldValidation) {
                scroll.scrollToFirstError();
            } else {
                // TODO: Add ARIA-LIVE announcement for error.
            }
        });

        // listen to apply policy event
        this.listenTo(this.model, 'widget:update', this.applyThePolicies);
    },

    /**
     * Temporary function to consolidate hacks for later removal
     */
    setupHacks() {
        const self = this;

        // TODO: explain this hack, and extract it out of this class properly
        if (this.model.isChild) {
            // FIXME: Should these be delegates like Backbone events: {}, rather than on
            // the actual elements?
            this.$el.off('click.mdf').on('click.mdf', 'button[name="ADDBENE"]', (e) => {
                e.stopImmediatePropagation();
                self.saveChild();
            });

            this.$('button[name="ADDCORR"]').off('click.mdf').on('click.mdf', (e) => {
                e.stopImmediatePropagation();
                self.saveChild();
            });

            if (this.gridComponentViews && !util.isEmpty(this.gridComponentViews)) {
                this.$('button[name="ADDADDENDA"]').off('click.mdf').on('click.mdf', (e) => {
                    e.stopImmediatePropagation();
                    self.saveGrandChild();
                });
            }
        }

        // TODO: Extract out of this class properly
        this.$('button[name="VIEWHISTORY"]').off('click.mdf').on('click.mdf', (e) => {
            e.stopImmediatePropagation();
            self.viewAuditHistory();
        });

        this.$('button[name="VIEWDISCLOSURE"]').off('click.mdf').on('click.mdf', (e) => {
            e.stopImmediatePropagation();
            self.viewDisclosure();
        });
        this.$('button[name="VIEWPREVIEWDISCLOSURE"]').off('click.mdf').on('click.mdf', (e) => {
            e.stopImmediatePropagation();
            self.viewPreviewDisclosure();
        });
    },

    /**
     * Set default data on the model prior to initializing various fields and widgets. In the
     * event of a driver field interaction, this function is responsible for populating the
     * new form model with previously filled in information.
     */
    setFormDefaults() {
        const setData = {};
        util.each(this.model.jsonData.fieldInfoList, (jsonDataItem) => {
            // TODO: Could we accumulate the model.set() calls, here?
            // set the default value for insert

            // combo box refresh triggers
            this.processDependsOnChange(jsonDataItem);

            if (this.state === 'insert' && jsonDataItem.value) {
                if (jsonDataItem.fieldUIType === 'CHECKBOX') {
                    if (jsonDataItem.value === jsonDataItem.checkboxOnValue) {
                        setData[jsonDataItem.name] = jsonDataItem.value;
                    }
                } else if ((jsonDataItem.fieldUIType === 'COMBOSELECT' || jsonDataItem.fieldUIType === 'COMBOBOX') && !util.isEmpty(this.model.get(jsonDataItem.name))) {
                    // Do not override with default value
                } else {
                    setData[jsonDataItem.name] = jsonDataItem.value;
                }
            }
        });
        this.model.set(setData);
    },

    /**
     * Setting up combo box refresh triggers, and insert form defaults. Skip if
     * entry by template.
     */
    setComboTriggers() {
        util.each(this.model.jsonData.fieldInfoList, (jsonDataItem) => {
            // set the default value for insert
            if (this.state === 'insert' && jsonDataItem.value) {
                // If the default value would trigger a combo refresh, do it.
                if (jsonDataItem.fieldUIType === 'TEXTBOX' && jsonDataItem.dependsOnMe) {
                    this.refreshDependsCombos(null, jsonDataItem);
                }
            }
        });
    },

    /**
     * Fetch data for typeahead collections and provide a promise for the completion.
     * @param {Model} model
     * @param {object} fieldData
     * @param {Collection} collectionParam
     * @param {string} queryTerm
     * @return {Promise}
     */
    createTypeAheadPromise(model, fieldData, collectionParam, queryTerm) {
        const self = this;
        const collection = collectionParam;

        return new Promise((resolve, reject) => {
            collection.depends = util.map(fieldData.dependsOn, (key) => {
                let dependsValue = model.get(key);
                if (util.isEmpty(dependsValue) && model.isChild) {
                    dependsValue = self.parentModel.get(key);
                }
                return {
                    name: key,
                    value: dependsValue,
                };
            });

            collection.startRow = 1;
            collection.queryTerm = queryTerm;
            collection.queryPage = 1;
            collection.fetch({
                success() {
                    const [result] = collection.models;
                    if (result) {
                        const setData = transform.pairsToHash(result.get('mapDataList'), 'toField', 'value');
                        model.set(setData);
                    }
                    resolve();
                },

                error() {
                    reject();
                },
            });
        });
    },

    /**
     * Fills out form model with loaded values,
     * initiates requests for typeahead fields
     * @returns {Promise} Typeahead Promises
     */
    runThroughPrefill() {
        // Nothing to do if no prefill
        if (util.isEmpty(this.preFill)) {
            // TODO: Should this be a promise?
            return undefined;
        }

        const self = this;
        const { model } = this;
        const preFillPairs = transform.hashToPairs(this.preFill, 'name', 'value');

        const preFillInModel = util.filter(preFillPairs, obj => model.has(obj.name));

        /*
         * TODO: There's probably a better way to filter the object
         * We might need to update underscore to get there, though.
         */
        const fillData = transform.pairsToHash(preFillInModel, 'name', 'value');
        model.set(fillData);

        return Promise.all(util.chain(preFillInModel)
            .filter((obj) => {
                const field = model.fieldData[obj.name];
                return !util.isEmpty(field && field.typeAhead);
            })
            .map((obj) => {
                const field = model.fieldData[obj.name];
                const { typeInfo } = model.jsonData;

                // use meta information when available to not hardcode fieldname
                const collection = new LookupCollection(
                    null,
                    {
                        fieldName: `${obj.name}LOOKUP`,
                        context: self.context,

                        typeInfo: {
                            productCode: typeInfo.productCode,
                            functionCode: typeInfo.functionCode,
                            typeCode: typeInfo.typeCode,
                        },

                        typeAheadFields: field.typeAhead,
                        typeAheadFree: field.typeAheadFree === null
                            ? false : field.typeAheadFree,
                    },
                );

                return self.createTypeAheadPromise(
                    model,
                    field,
                    collection,
                    self.preFill[obj.name],
                );
            })
            .value());
    },

    /**
     * Configure date data from view setup promises.
     * @param {*} dates
     * @param {Model} dateRange
     */
    setupViewDates(dates, dateRange) {
        this.dates = dates;
        if (dateRange) {
            this.minDate = dateRange.get('minDate');
            this.maxDate = dateRange.get('maxDate');
            // was a date range found?
            this.dateRange = dateRange.get('rangeSet');
        }
    },

    /**
     * Generate combobox collections from view setup data.
     * @param {Object} results
     * @returns {Object} Hash of combobox data.
     */
    createComboCollections(results) {
        return util.reduce(this.model.comboList, (acc, combo, index) => {
            const result = results[`combo-${index}`];
            const name = combo.inquiryId ? combo.name : combo;

            if (!result || result.queryResponse.QueryData.queryRows === null) {
                acc[name] = [];
            } else {
                acc[name] = (this.comboBoxData && this.comboBoxData[name])
                    ? this.comboBoxData[name] : result.queryResponse.QueryData.queryRows;
            }
            return acc;
        }, {});
    },

    /**
     * Called after the Promise hash
     * @param {Object} options - options passed from loadViewRequirements
     */
    setupViewRequirements(options) {
        // update the view's model with any retrieved data on page load
        viewHelper.updateModels(this.model, this);

        this.model.staticDependsOn = options.staticDependentFields
            ? options.staticDependentFields : null;

        // update the model structure
        // set up model promises for model combos,dates, etc
        const promiseHash = viewHelper.getComboPromises(options, this.model);

        if (this.shouldAddDatePromises()) {
            promiseHash.date = viewHelper.getDatePromises(options, this.model);
            promiseHash.dateRange = viewHelper.getDateRangePromise(options);
        }

        // don't send the request for the toggle for a view in a  multi-add page
        if (util.isNullOrUndefined(this.options.itemIndex)) {
            promiseHash.stateToggle = viewHelper.getPromisesForStateToggle(this.model);
        }

        if (!options.hideButtons) {
            promiseHash.entitlements = entitlements.getEntitlements(options);
        }

        // set a flag to identify that the parent failed
        const inError = !!(options.parentModel && options.parentModel.error
            && options.parentModel.error.errorCode !== 'undefined');

        if (options.isChild && this.state === 'insert') {
            // NH-47905 if this is a new Batch Payment, default child number to 1 and
            // avoid a Rest Call
            // NH-55692 If this is a payment being created from another payment
            // we can't assume the child number will be 1
            if (this.useBeneWidget && (this.parentView.state === 'insert' && this.parentView.context.createdFrom !== '1')) {
                promiseHash.childNumber = 1;
            } else {
                promiseHash.childNumber = viewHelper.getChildNumberPromise(options);
            }
        } else if (this.model.isBatch && this.state === 'insert' && this.context.createdFrom !== '1') {
            promiseHash.batchSeqNumber = viewHelper.getSequenceNumberPromise(options);
        }

        // get the helppage for this workspace
        if (!options.isChild) {
            promiseHash.helpPagePromise = viewHelper.getHelpPagePromise({
                mode: this.state,
            }, this.model);
        }

        hash(promiseHash).then((results) => {
            if (results.stateToggle && results.stateToggle.queryResponse) {
                this.stateToggleData = results.stateToggle.queryResponse.QueryData;
            }

            if (this.shouldAddDatePromises()) {
                this.setupViewDates(results.date, results.dateRange);
            }
            if (!options.hideButtons) {
                this.entitlements = results.entitlements.actions;
            }
            /*
             * If the parent is in error, we want to use the childNumber of the current,
             * and not a new one. Also, if the parent is in error, this condition only matters
             * when not in a modal (popup)
             */
            if (options.isChild && this.state === 'insert' && (!inError || options.isModal)) {
                this.model.childNumber = results.childNumber;
            }

            if (this.model.isBatch && this.state === 'insert' && this.context.createdFrom !== '1') {
                if (!util.isNullOrUndefined(results.batchSeqNumber)) {
                    this.model.set('BATCHSEQNUM', results.batchSeqNumber);
                    if (this.beneWidget) {
                        this.beneWidget.batchSeqNum = results.batchSeqNumber;
                    }
                }
            }

            // store the helpPage in cache for the workspace.
            if (!options.isChild) {
                store.set('helpPage', results.helpPagePromise.helpPage);
            }

            this.comboCollections = this.createComboCollections(results);

            this.setHasLoadedRequiredData(true);
            if (!this.refreshDriverFields) {
                this.trigger('loaded');
            } else {
                this.refreshDriverFields = false;
            }

            /*
             * footer forms shouldn't be rendered until they're opened. This is
             * handled in entry.js
             */
            if (!options.footerForm) {
                this.render();
                this.listenTo(this.model, 'change', this.handleModelChangeEvents);
            }
        }, util.bind(errorHandlers.loading, this));
    },

    /**
     * Get the initial data.
     * @param {Object} options
     */
    loadViewRequirements(options) {
        // FIXME: What the hell does this represent??
        const isRightState = this.model
            && (this.state === 'view'
                || this.state === 'modify'
                || this.state === 'restore'
                || (this.state === 'insert' && this.model.get('ENTRYMETHOD') === constants.ENTRY_METHOD.FREEFORM));

        let copyModel = (model) => {
            this.model = model;
        };

        moveToTopCheck(this.model);

        let modelPromise;
        if (this.reloadingDriverField !== '') {
            if (this.model.context
                && this.model.context.entrymethod === constants.ENTRY_METHOD.FREEFORM
                && !this.model.context.actionData && !this.model.context.functionCode) {
                this.model.context.functionCode = this.model.get('FUNCTION');
            }

            // NH-43023 - Defining the following var to determine whether to trigger
            // the "loaded" event at line 387 above.
            this.refreshDriverFields = false;
            /**
             * The model contains the list of driver fields.
             * Make sure that the call to /refreshModelInfo (reload the page) is only
             * called when
             * the value for each of the driver fields is set
             */
            if (this.isFilled(this.model.driverFields)
                && this.validateValuesInComboDriverFields(this.model, this.model.driverFields)) {
                this.refreshDriverFields = true;
                /**
                 * Since the page is going to be reloaded stop the listeners as
                 * they will be reconfigure after the page is reloaded
                 */
                this.stopListening(this.model, 'change', this.handleModelChangeEvents);

                this.toggleSpinnerOverlay(true);
                modelPromise = viewHelper.reloadModel({
                    model: this.model,
                });
            }

            if (isRightState) {
                copyModel = (model) => {
                    this.model.jsonData = model.jsonData;
                    this.model.fieldData = model.fieldData;
                    this.model.validators = model.validators;

                    if (this.formReloadedDriverFieldName === 'CREDIT_CURRENCY' && (this.state === 'modify' || this.state === 'restore')) {
                        this.model.formReloadedFromDriverField = true;
                        this.formReloadedDriverFieldName = '';
                    }
                };
            }
            this.formReloadedDriverFieldName = this.reloadingDriverField;
            this.reloadingDriverField = '';
        } else {
            let foundOne = false;
            if (this.model && (isRightState || (this.state === 'insert' && this.model.get('TYPE') === 'FEDTAX'))) {
                if (!util.isEmpty(this.model.driverFields)) {
                    foundOne = util.some(
                        this.model.driverFields,
                        field => !util.isEmpty(this.model.get(field)),
                    );
                }
            }

            // TODO: foundOne is only possibly true inside the block above. Maybe refactor?
            if (foundOne) {
                this.toggleSpinnerOverlay(true);
                modelPromise = viewHelper.reloadModel({
                    model: this.model,
                });
                copyModel = (model) => {
                    this.model.jsonData = model.jsonData;
                    this.model.fieldData = model.fieldData;
                    this.model.validators = model.validators;
                };
            } else {
                modelPromise = viewHelper.getModel(options);
            }
        }

        if (modelPromise === undefined) {
            return;
        }

        modelPromise.then((model) => {
            copyModel(model);
            return this.setupViewRequirements(options);
        }).then(() => {
            this.toggleSpinnerOverlay(false);
        }, util.bind(errorHandlers.loading, this));
    },

    /**
     * @method isCombo
     * @param {string} selector
     *
     * method to check whether provided selector represents a combo
     */
    isCombo(selector) {
        return this.$(selector).length > 0 && this.$(selector)[0].tagName === 'SELECT';
    },

    /**
     * For any combo driver field combo, validate the value in the model is a
     * valid option in the combo
     *
     * Return *not some* (none) of them fields were wrong.
     * TODO: This could use util.every() but I didn't want to mess up refactoring the predicate.
     *
     * @method validateValuesInComboDriverFields
     * @param {String} model
     * @param {Array} fields - driver fields
     * @returns {boolean}
     */
    validateValuesInComboDriverFields(model, fields) {
        return !util.some(fields, (field) => {
            const $driverField = $(`[name="${field}"]`);
            // Checks for a mis-match.
            return $driverField.length > 0
                && $driverField[0].tagName === 'SELECT'
                && $driverField.find(`option[value="${model.get(field)}"]`).length === 0;
        });
    },

    /**
     * @method isFilled
     * @param {Array} fields - The list of fields to test
     * @returns {Boolean} - returns true if all the fields have a value
     *
     * Check whether each of the fields are populated
     */
    isFilled(fields) {
        return util.every(fields, function (fieldName) {
            const fieldValue = this.model.get(fieldName);
            return !(fieldValue === undefined || fieldValue === '');
        }, this);
    },

    /**
     * @return {boolean}
     */
    shouldAddDatePromises() {
        return !(this.isAdminPayment() || this.isAdminTemplate());
    },

    /**
     * @return {boolean}
     */
    isAdminPayment() {
        return this.context.serviceName === 'adminPayment/listView/payments';
    },

    /**
     * @return {boolean}
     */
    isAdminTemplate() {
        return this.context.serviceName === 'adminTemplate/listView/templates';
    },

    /**
     *
     * @param {string} policyName
     * @return {string}
     */
    adjustPolicyName(policyName) {
        return policyName.replace('/payment/', '/template/').replace('/template/', '/*/');
    },

    /**
     * Build the policyName
     * @return {string}
     */
    getPolicyName() {
        let policyName = this.adjustPolicyName(this.context.serviceName);

        if (this.context.subType && this.context.subType !== '*' && policyName.indexOf('Wire-International') === -1) {
            if ((policyName.indexOf('adminPayment/listView/payments') > -1 || policyName.indexOf('adminTemplate/listView/templates') > -1) && this.model && this.model.get('TYPE') === 'INTL') {
                // do not add subtype for admin payment intl
            } else {
                policyName += `-${this.context.subType}`;
            }
        }

        if (this.model.isChild) {
            policyName += '-child';
        }

        return policyName;
    },

    loadPolicies() {
        // Get the current view form and load the appropriate policy based on context.
        const viewForm = f1.getForm(this);

        const policyName = this.getPolicyName();

        let contextPolicy = policies[policyName];

        // Make sure it is an array for consistent logic.
        if (!util.isArray(contextPolicy)) {
            contextPolicy = [contextPolicy];
        }

        util.forEach(contextPolicy, (policy) => {
            viewForm.addPolicy(policy);
        });

        viewForm.applyPolicies(true);

        this.pageForm = viewForm;
    },

    handleModelChangeEvents(model) {
        // Skip if no driverFields or we are already reloading
        if (util.isEmpty(model.driverFields) || this.reloadingDriverField !== '') {
            return;
        }

        const changedAttributes = model.changedAttributes();
        const changedField = util.find(
            model.driverFields,
            field => Object.prototype.hasOwnProperty.call(changedAttributes, field),
        );

        if (changedField === undefined) {
            return;
        }

        /*
         * NH-151503 & NH-161208 - When the beneficiary bank triggers the driver field update event
         * there could be active network calls for preferred intermediary or admin intermediary
         * map. (These two processes are mutually exclusive.) This causes the intermediary
         * information to be overwritten by the concurrent driver field refresh. For this reason
         * we will defer the driver field refresh until the intermediary lookup is complete.
         *
         * NH-164158 - This is now also relevant on rare occasion for the originator dropdown
         * on wire intl payments/templates
         */
        if (model.scheduledPreferredListUpdate) {
            this.reloadingDriverField = changedField;
            this.listenToOnce(model, 'preferredListUpdateComplete', () => {
                this.saveCombosAndLoadViewRequirements(model, changedField);
            });
            return;
        }

        this.saveCombosAndLoadViewRequirements(model, changedField);
    },

    /**
     * Save the current state of all combos on the view, then re-render the view
     * @param {Model} model - the changed model
     * @param {String} changedField - the changed field on the model
     */
    saveCombosAndLoadViewRequirements(model, changedField) {
        this.lastComboValues = util.reduce(
            this.comboCollections,
            (acc, val, comboKey) => {
                acc[comboKey] = model.get(comboKey);
                return acc;
            }, {},
        );

        // a driver field changed! refresh the model
        this.reloadingDriverField = changedField;
        this.setHasLoadedRequiredData(false);
        util.defer(() => this.loadViewRequirements(this.initializeOptions));
    },

    /**
     * Stops event propagation on readOnly elements.
     * @return {boolean}
     */
    handleReadOnlyClick() {
        return false;
    },

    /**
     * Manipulate numeric fields on focus
     * @param {Event} e
     */
    handleFocusEvent(e) {
        const tVal = this.model.get(e.currentTarget.name);
        if (tVal === '0.00') {
            this.model.set(e.currentTarget.name, '');
        }
    },

    viewDisclosure() {
        const printOptions = {
            tnum: this.model.get('TNUM'),
            outputFormat: 'PDF',
            pageType: 'LETTER',
            viewId: 'WIREINTLDISCLOSURE',
            serviceCode: 'WIREINTLDISCLOSURE',
            dynamicReportURL: `${reportConstants.WIRE_DISCLOSURE_PREFIX}reportView`,
        };
        DynamicReportUtil.print(printOptions);
    },

    viewPreviewDisclosure() {
        const printOptions = {
            tnum: this.model.get('TNUM'),

            additionalProperties: {
                bankName: this.model.get('BANKNAME').replace(/%/g, '%25'),
                bankAddress: this.model.get('ADDRESS').replace(/%/g, '%25'),
                legalDisclosure: this.model.get('LEGALDISCLOSURE').replace(/%/g, '%25'),
            },

            outputFormat: 'PDF',
            pageType: 'LETTER',
            viewId: 'WIREINTLDISCLOSURE',
            serviceCode: 'WIREINTLDISCLOSURE',
            dynamicReportURL: `${reportConstants.WIRE_DISCLOSURE_PREFIX}reportView`,
        };
        DynamicReportUtil.print(printOptions);
    },

    processBeneficiaryUpdatedApprovalWarning(model) {
        if (model.error.confirms.chainedAction) {
            /*
             * Must capture these for the user's decision to continue to succeed.
             * At this point, on insert, the model has no TNUM and in all cases
             * the UPDATECOUNT__ will have changed.
             */
            if (model.isNew()) {
                model.set('id', model.error.confirms.confirmResults[0].keyValue);
                model.set('TNUM', model.error.confirms.confirmResults[0].keyValue);
            }
            model.set('UPDATECOUNT__', model.error.confirms.confirmResults[0].updateCount);
        }
        Dialog.custom(new BeneUpdatedApprovalWarning({
            model,
            resp: model.error,
            isMultiApprove: false,
            context: this.options.context,
        }));
    },

    processBeneficiaryUpdatedWarning(model) {
        let contactHasBeenUpdatedValue = '';
        let orderingPartyUpdatedValue = '';
        const { formView } = this.pageForm;
        const additionalData = model.error.confirms.confirmResults[0].additionalData[0].item;

        (additionalData || []).forEach((field) => {
            if (field.name === 'CONTACTHASBEENUPDATED') {
                model.set('CONTACTHASBEENUPDATED', field.value);
                contactHasBeenUpdatedValue = field.value;
            }
            if (field.name === 'ORDERINGPARTYCONTACTUPDATED') {
                model.set('ORDERINGPARTYCONTACTUPDATED', field.value);
                orderingPartyUpdatedValue = field.value;
            }
        });

        Dialog.custom(new BeneUpdatedWarning({
            model,
            contactHasBeenUpdatedValue,
            orderingPartyUpdatedValue,
            formView,
        }));
    },

    /**
     * @param {model} model
     * Initialize the Duplicate Payment Warning Modal.
     */
    processDuplicatePayment(model) {
        Dialog.custom(new DuplicateDialog({
            model,
            methodName: this.context.actionMode.toUpperCase(),
        }));
    },

    /**
    * @param {model} model
    * @return {boolean}
    * Check if the warning received from the server is indicating a duplicate payment.
    */
    isDuplicatePaymentWarning(model) {
        const [warningMessage] = model.error.confirms.confirmResults[0].messages;

        if (warningMessage) {
            return warningMessage.includes(locale.get('PAY.possibleDuplicate'));
        }
        return false;
    },

    viewAcceptDisclosure(model) {
        const printOptions = {
            tnum: this.getTnumFromConfirmModel(model),
            outputFormat: 'PDF',
            pageType: 'LETTER',
            viewId: 'WIREINTLDISCLOSURE',
            serviceCode: 'WIREINTLDISCLOSURE',
            dynamicReportURL: `${reportConstants.WIRE_DISCLOSURE_PREFIX}reportView`,
            isApprovalDisclosure: true,
            approvalDisclosureModel: model,
            approvalDisclosureGrid: this.gridView,
            updateCount: this.getUpdateCountFromConfirmModel(model),
            approvalDisclosureResponse: model.error,
            actionMode: this.context.actionMode,
        };
        if (!printOptions.tnum) {
            printOptions.additionalProperties = model.attributes;
        }
        if (this.context.actionMode !== 'INSERT' || (this.context.actionMode === 'INSERT' && model.error.errorCode === constants.CONSUMER_DISCLOSURE_ERROR_CODE)) {
            model.generateIdValue();
        }
        DynamicReportUtil.print(printOptions);
    },

    /**
     * TODO: Clarify the return type and the tnum.
     * @param {Model} model
     * @return {*}
     */
    getTnumFromConfirmModel(model) {
        let tnum;
        try {
            tnum = model.error.confirms.confirmResults[0].confirmData[0].item[0].value;
        } catch (err) {
            tnum = model.get('TNUM') || '';
        }
        return tnum;
    },

    /**
     *
     * @param {Model} model - Backbone Model
     * @return {string|number}
     */
    getUpdateCountFromConfirmModel(model) {
        let updateCount;
        try {
            const confirmItem = model.error.confirms.confirmResults[0].confirmData[0].item.filter(item => item.name === 'UPDATECOUNT__');
            updateCount = confirmItem[0].value;
        } catch (err) {
            updateCount = '';
        }
        return updateCount;
    },

    viewAuditHistory() {
        printExportUtil.viewAuditHistory(this.model);
    },

    processDependsOnChange(jsonDataItem) {
        const self = this;

        if (jsonDataItem.dependsOnMe) {
            // if there are any comboboxes that are dependent on me. then add on change
            // to trigger the refresh of the combos
            // if it is a combobox, adding event to on change of the model. else adding
            // to the view. because the model onchange is being triggered
            // for each key up for the textboxes.
            if (jsonDataItem.fieldUIType !== 'TEXTBOX') {
                this.model.on(`change:${jsonDataItem.name}`, (e) => {
                    self.refreshDependsCombos(e, jsonDataItem);
                });
                if (this.parentModel
                    && this.parentModel.fieldData[jsonDataItem.name] !== null) {
                    this.parentModel.on(`change:${jsonDataItem.name}`, (e) => {
                        self.refreshDependsCombos(e, jsonDataItem);
                    });
                }
            } else {
                this.$el.find(`[name=${jsonDataItem.name}]`).on('change', (e) => {
                    self.refreshDependsCombos(e, jsonDataItem);
                });
            }
        }
    },

    refreshDependsCombos(e, jsonDataItem) {
        const self = this;
        let doUpdateFlag = false;

        // until we fix watch for the on change on the model rather than on change
        // on select2:
        // if this combo didn't change then don't update it's dependsOnMe fields
        if (this.lastComboValues) {
            if (this.lastComboValues[jsonDataItem.name]) {
                if (this.lastComboValues[jsonDataItem.name]
                    !== this.model.changedAttributes()[jsonDataItem.name]) {
                    doUpdateFlag = true;
                }
            }
        } else {
            doUpdateFlag = true;
        }

        // NH-43897 - if the combo box is protected don't update its dependsOnMe fields
        if (this.$el.find(`[name=${jsonDataItem.name}]`).prop('readOnly')) {
            doUpdateFlag = false;
        }

        const promiseHash = viewHelper.getComboPromises({
            context: this.context,
            parentModel: this.parentModel,
        }, this.model, jsonDataItem.dependsOnMe);

        const daPageForm = this.pageForm;

        hash(promiseHash).then((results) => {
            const { dependsOnMe } = jsonDataItem;
            let dependsSelectValue;

            const triggerOnChange = function (control, value) {
                control.val(value).trigger('change');
            };

            util.forEach(dependsOnMe, (fieldName, i) => {
                let $select = self.$el.find(`select[name=${fieldName}]`);
                const isReadOnly = $select.prop('readonly') === true;
                const field = self.model.fieldData[fieldName];

                if (field.fieldUIType === 'MULTICHECKFILTER') {
                    // MULTICHECKFILTER is input type=hidden. Need to ensure that we
                    // narrow down to the correct fieldName
                    self.$el.find(`input[name=${fieldName}]`).select2('val', null).trigger('change');
                    return;
                }

                // Don't apply depends on combo box code for items that are no longer comboboxes
                if (isReadOnly || field.fieldUIType !== 'COMBOBOX') {
                    return;
                }

                if (results[`combo-${i}`].queryResponse.QueryData.queryRows === null) {
                    // blank out the currently selected value, prior to invalidating
                    // collection
                    $select.val(null).trigger('change');
                } else {
                    self.comboCollections[fieldName] = results[`combo-${i}`].queryResponse.QueryData.queryRows;
                    comboboxHelper.setupComboboxes(self, `#${fieldName}`);

                    // update tempSelect2 after setupComboboxes recreates the combo
                    $select = self.$el.find(`select[name=${fieldName}]`);
                    // check if we need to select the first item in the dependent combo
                    comboboxHelper.checkForSelectIfOnlyOne(self, `#${fieldName}`);

                    /*
                     * Trigger change on select2 to update the select control.
                     * This needs to be done in a defer for timing considerations.
                     * We also need to save off the value b/c when the defer function
                     * is invoked the model doesn't have the correct value
                     */
                    dependsSelectValue = self.model.get(fieldName);
                    util.defer(triggerOnChange.bind(self, $select, dependsSelectValue));
                    self.appBus.trigger('combo-collections-updated', [fieldName]);
                }

                $select.html(viewHelper.getOptionsForCombo(self.comboCollections[fieldName]));

                // do a single prefill check
                if (Object.prototype.hasOwnProperty.call(self.preFill, fieldName)
                    && self.preFill[fieldName] !== null) {
                    $select.val(self.preFill[fieldName]).trigger('change');
                    self.preFill[fieldName] = null;
                } else if (doUpdateFlag) {
                    $select.val(null).trigger('change');
                } else if (self.lastComboValues) {
                    $select.val(self.lastComboValues[fieldName]).trigger('change');
                }
            });

            if (daPageForm) {
                util.forEach(dependsOnMe, (prereq) => {
                    daPageForm.refreshField(`#${prereq}`, self);
                });
            }
        }, util.bind(errorHandlers.intraPage, self));
    },

    /**
     * @method applyThePolicies
     * @param {boolean} initialState - whether or not the metadriven form is to
     * be rendered in initial state
     * Will generate the forms and apply the appropriate policy event handling
     * files on them.
     * Passing in initialstate will determine the behavior of the forms on initial render
     */
    applyThePolicies(initialState) {
        this.pageForm.applyPolicies(util.isBoolean(initialState) ? initialState : false);
    },

    popChildModal(view) {
        this.modalChildView = view;
        this.listenTo(this.modalChildView, 'childSaved', this.handleChildModalSaved);
        this.listenTo(this.modalChildView, 'childCanceled', this.handleChildModalCanceled);
        // in case there is an activeModal remaining from an action like Reject;
        // temporary fix till the issue is fixed in glu
        Dialog.activeModal = false;
        this.openDialog(this.modalChildView);
    },

    // refresh the grand child grid and clear the grand child fields from the child model.
    handleGrandChildAdd() {
        let pageView;
        const self = this;

        if (this.model.isBatch) {
            pageView = this.childView;
        } else {
            pageView = self;
        }

        if (this.isCTX) {
            pageView.gridComponentViews.GRANDCHILD.refreshCollectionData();
        } else {
            const grandChildColumns = pageView.model.grandChildFields;
            const gridDomId = `[data-field="${pageView.model.grandChildName}"]`;

            pageView.gridComponentViews[pageView.model.grandChildName].refreshGridData();

            // clear all the addenda values.
            util.forEach(grandChildColumns, (columnName) => {
                if (pageView.model.has(columnName)) {
                    if (columnName !== pageView.model.childSequenceKey.toUpperCase()) {
                        pageView.model.set(columnName, '');
                    }
                }
            });

            pageView.$(gridDomId).closest('.grid-container').show();
            // change the button label back to  Add (on Modify it was changed to Update)
            pageView.$('button[name="ADDADDENDA"]').text(locale.get('common.addenda.add'));
        }
    },

    handleGrandChildDelete() {
        let pageView;
        const self = this;

        if (this.model.isBatch) {
            pageView = this.childView;
        } else {
            pageView = self;
        }

        if (this.isCTX) {
            pageView.gridComponentViews.GRANDCHILD.refreshCollectionData();
        } else {
            pageView.gridComponentViews[pageView.model.grandChildName].refreshGridData();
        }
    },

    componentGridRowAction(options) {
        if (options.action.toUpperCase() === 'MODIFY') {
            this.model.set(options.model.attributes);
            // TODO get the message from the server. or add them to the data attributes
            this.$('button[name="ADDADDENDA"]').text(locale.get('common.addenda.update'));
        }
        if (options.action.toUpperCase() === 'DELETE') {
            this.deleteGrandChild(options.model);
        }
    },

    setupChildGridView() {
        if (this.childView && this.childView.model) {
            this.initializeOptions.lockedFields = this.childView.model.lockedFields;
        }
        this.$el.find('[data-region="childViewRegion"]').hide();
        this.$el.find('[data-region="childGridRegion"]').show();
        this.initializeOptions.batchSeqNum = this.model.get('BATCHSEQNUM');
        this.batchChildGridView = new BatchChildGridView(this.initializeOptions);

        this.listenTo(this.batchChildGridView, 'addChild', this.handleChildAddFromGrid);
        this.listenTo(this.batchChildGridView, 'modifyChild', this.handleChildModifyFromGrid);
        this.listenTo(this.batchChildGridView, 'viewChild', this.handleChildViewFromGrid);
        this.childGridRegion.show(this.batchChildGridView);

        this.listenTo(this.appBus, 'childSaveSuccessFromGrid', this.handleChildAddedFromGrid);
    },

    setupNewChildModal() {
        const newOptions = {
            state: 'insert',
            isChild: true,
            isModal: true,
            parentModel: this.model,
            context: this.initializeOptions.context,
            gridApi: this.initializeOptions.gridApi,
            hideButtons: this.initializeOptions.hideButtons,
            beneWidget: this.beneWidget,
        };

        const batchChildView = new MetaDrivenForm(newOptions);

        const modalChildView = new BatchChildEntry({
            batchChildView,
        });

        this.listenTo(modalChildView.batchChildView, 'lookup:layover', (options) => {
            if (options.view === null) {
                this.ui.$layover.hide();
            } else {
                this.ui.$layover.css({
                    /*
                     * HACK
                     * We should not have modal on top of modal.
                     * The original impl using pageY did not work
                     * in all cases when the modal was displayed;
                     * it was too low.  This just takes the position of
                     * the first modal and just adds 200 to place the next
                     * modal at a reasonable height.
                     */
                    top: this.ui.$layover.position().top + 200,

                    left: this.ui.$layover.position().left + 200,
                    position: 'absolute',
                });
                this.ui.$layover.show();
                this.layover.show(options.view);
            }
        });

        this.popChildModal(modalChildView);
    },

    handleChildAdd() {
        if (this.batchChildGridView === null) {
            this.setupChildGridView();
        }
        // NH-40719 - When using the beneWidget, prevent the new view creation.
        if (this.useBeneWidget) {
            // NH-47905
            if (this.beneWidget.model.childNumber > 1 && ['insert', 'modify', 'restore'].includes(this.state)) {
                this.batchChildGridView.gridView.refreshGridData();
            }
            return;
        }

        this.setupNewChildModal();
    },

    handleChildModifyFromGrid(model) {
        const self = this;

        const cloneModel = new MetaModel(
            {},
            {
                context: model.context,
                jsonData: model.jsonData,
                lockedFields: self.model.lockedFields,
            },
        );

        cloneModel.setupFromData();
        this.listenTo(this.appBus, 'childSaveSuccessFromGrid', this.handleChildAddedFromGrid);

        util.forEach(model.attributes, (value, key) => {
            if (cloneModel.fieldData[key] && cloneModel.fieldData[key].fieldUIType && cloneModel.fieldData[key].fieldUIType === 'CHECKBOX') {
                if (value === cloneModel.fieldData[key].checkboxOffValue) {
                    model.set(
                        key,
                        '',
                        {
                            silent: true,
                        },
                    );
                }
            }
        });

        cloneModel.set(model.attributes);

        if (self.context.actionData && self.context.actionData.entryMethod) {
            cloneModel.set(constants.ENTRYMETHOD, self.context.actionData.entryMethod);
        }

        cloneModel.childNumber = model.childNumber;

        const newOptions = {
            isChild: true,
            isModal: true,
            model: cloneModel,
            state: 'modify',
            parentModel: self.model,
            beneWidget: self.beneWidget,
        };

        const modalChildView = new BatchChildEntry({
            batchChildView: new MetaDrivenForm(util.extend(
                {},
                this.initializeOptions,
                newOptions,
            )),
        });

        this.listenTo(modalChildView.batchChildView, 'lookup:layover', (options) => {
            if (options.view === null) {
                self.ui.$layover.hide();
            } else {
                // HACK (desired one-off case)
                // one off case for only layovers opened from Beneficiary modals
                if (self.context.subType === 'NACHA') {
                    const modalEl = Dialog.activeModal.$el.find('.modal-content');
                    self.ui.$layover.css({
                        'min-height': modalEl.height(),
                        position: 'fixed',
                    });
                    self.ui.$layover.addClass('fluid-sizing beneficiary');
                } else {
                    self.ui.$layover.css({
                        top: options.event.pageY,

                        // left: options.event.pageX,
                        position: 'absolute',
                    });
                }
                self.ui.$layover.show();
                self.layover.show(options.view);
            }
        });

        this.popChildModal(modalChildView);
    },

    handleChildViewFromGrid(model) {
        const self = this;

        const newOptions = {
            isChild: true,
            isModal: true,
            model,
            state: 'view',
            parentModel: self.model,
            beneWidget: self.beneWidget,
        };

        const modalChildView = new BatchChildEntry({
            batchChildView: new MetaDrivenForm(util.extend(
                {},
                this.initializeOptions,
                newOptions,
            )),
            state: 'view',
        });

        this.popChildModal(modalChildView);
    },

    handleChildAddedFromGrid() {
        Dialog.close();
        this.batchChildGridView.gridView.refreshGridData();
        this.handleChildAdd();
    },

    handleChildAddFromGrid() {
        this.handleChildAdd();
    },

    handleChildModalCanceled() {
        // TODO: This needs to revisited and populate this.layover.currentView properly so it
        // would not be undefined.
        // Currently checking for this.layover.currentView since this.layover.currentView is
        // undefined currently and so view/modify child grid payment was failing
        // close layover modal before main modal closes
        if (this.layover.currentView) {
            this.layover.currentView.trigger('lookup:cancel');
        }

        Dialog.close();
        this.modalChildView.batchChildView.trigger(
            'lookup:layover',
            {
                view: null,
            },
        );
    },

    handleChildModalSaved() {
        // TODO: This needs to revisited and populate this.layover.currentView properly so it
        // would not be undefined.
        // Currently checking for this.layover.currentView since this.layover.currentView is
        // undefined currently and so view/modify child grid payment was failing
        // close layover modal before main modal closes
        if (this.layover.currentView) {
            this.layover.currentView.trigger('lookup:cancel');
        }

        // make sure batchChildGridView lockedFields are updated gridView
        if (this.batchChildGridView.model) {
            this.batchChildGridView.gridView.lockedFields = this.batchChildGridView
                .model.lockedFields;
        }

        Dialog.close();
        this.batchChildGridView.gridView.refreshGridData(false, true);
    },

    /**
     *
     * @return {boolean}
     */
    isGrandChildDirty() {
        const pageView = this.model.isBatch ? this.childView : this;
        let retVal = false;
        const grandChildCount = pageView.gridComponentViews
            && pageView.gridComponentViews.GRANDCHILD
            && pageView.gridComponentViews.GRANDCHILD.getCollectionLength
            ? pageView.gridComponentViews.GRANDCHILD.getCollectionLength()
            : 0;

        if (!util.isEmpty(pageView.model.grandChildName)
            && !util.isEmpty(pageView.model.grandChildFields)) {
            retVal = util.some(pageView.model.grandChildFields, (field) => {
                if (['WORKSEQNUMBER', 'ACTIONLIST', 'IDCODE', 'REFERENCEID'].indexOf(field) === -1) {
                    return grandChildCount && field === 'ZERODOLLARLIVE' ? false : !util.isEmpty(pageView.model.get(field));
                }
                return false;
            });
        }

        return retVal;
    },

    saveModalChild() {
        const self = this;

        self.model.set('BATCHSEQNUM', self.parentModel.get('BATCHSEQNUM'));
        // save the grand child first
        if (self.isGrandChildDirty()) {
            /*
             * When a grandChild save fails, this listern has already been added,
             * so before adding the listener again, remove it, to make sure that we
             * don't have multiple callbacks firing
             */
            self.stopListening(self, 'grandChildSaveSuccess');
            self.listenToOnce(self, 'grandChildSaveSuccess', () => {
                self.model.save(
                    {},
                    {
                        success() {
                            self.trigger('childModalSaveSuccess');
                        },

                        error() {
                            self.trigger('childSaveError');
                        },
                    },
                );
            });

            self.saveGrandChild();
        } else {
            self.model.save(
                {},
                {
                    success() {
                        self.trigger('childModalSaveSuccess');
                    },

                    error() {
                        self.trigger('childSaveError');
                        self.trigger('modelAction:error', self.model);
                    },
                },
            );
        }
    },

    saveChild() {
        const self = this;

        self.model.set('BATCHSEQNUM', self.parentModel.get('BATCHSEQNUM'));

        // save the grand child first
        if (self.isGrandChildDirty()) {
            /*
             * When a grandChild save fails, this listern has already been added,
             * so before adding the listener again, remove it, to make sure that we
             * don't have multiple callbacks firing
             */
            self.stopListening(self, 'grandChildSaveSuccess');
            self.listenToOnce(self, 'grandChildSaveSuccess', () => {
                self.model.save(
                    {},
                    {
                        success() {
                            self.trigger('childSaveSuccess');
                        },

                        error() {
                            self.trigger('childSaveError');
                            self.trigger('modelAction:error', self.model);
                        },
                    },
                );
            });

            self.saveGrandChild();
        } else {
            this.model.save(
                {},
                {
                    success() {
                        // (self.batchChildGridView !== null) {
                        if (self.initializeOptions.isModal) {
                            self.appBus.trigger('childSaveSuccessFromGrid');
                        } else {
                            self.trigger('childSaveSuccess');
                        }
                    },

                    error() {
                        self.trigger('childSaveError');
                        self.parentView.trigger('modelAction:error', self.model);
                    },
                },
            );
        }
    },

    saveGrandChild(opts) {
        const self = this;
        const { model } = self;

        if (this.isCTX) {
            if (util.isNullOrUndefined(model.get('BATCHSEQNUM'))) {
                model.set('BATCHSEQNUM', self.parentModel.get('BATCHSEQNUM'));
            }
        }

        const cloneModel = new MetaModel(
            {},
            {
                context: model.context,
                jsonData: model.jsonData,
            },
        );

        cloneModel.setupFromData();
        cloneModel.set(model.attributes);

        const errorFunctionPrime = function (errModel) {
            const errors = errModel.error.message.join(', ');
            if (self.isGrandChildDirty()) {
                if (self.parentView && self.parentView.childView) {
                    self.parentView.childView.stopListening(self.parentView.childView, 'grandChildSaveSuccess');
                } else {
                    self.stopListening(self, 'grandChildSaveSuccess');
                }
            }
            if (self.viewType === 'modal') {
                $('#error-message').text(errors).show();
                $('.modal-body').animate({
                    scrollTop: 0,
                }, 100);
            } else if (self.hideButtons === true) {
                if (self.initializeOptions.isModal) {
                    self.trigger('modelAction:error', errModel);
                } else {
                    self.parentView.trigger('modelAction:error', errModel);
                }
            } else {
                Dialog.alert(errors, 'ERROR');
            }
        };

        let errorFunction = errorFunctionPrime;
        if (opts && opts.errorCallback) {
            errorFunction = function (args) {
                opts.errorCallback.call();
                errorFunctionPrime.call(this, args);
            };
        }

        const options = {
            success() {
                self.trigger('grandChildSaveSuccess');
                self.clearAddenda();
            },

            error: errorFunction,
            model: cloneModel,
        };

        if (this.isCTX && util.isNullOrUndefined(model.get('BATCHSEQNUM'))) {
            model.set('BATCHSEQNUM', self.parentModel.get('BATCHSEQNUM'));
        }

        if (this.isCTX && opts && opts.model) {
            cloneModel.set(opts.model.attributes);
        }

        this.model.saveGrandChild(options);
    },

    clearAddenda() {
        this.model.set({
            RMT_REFNUMBERQUALIFIER: '',
            RMT_REFNUMBER: '',
            PAIDINVOICEAMOUNT: '',
            TOTALINVOICEAMOUNT: '',
            TERMSDISCOUNT: '',
            REF_REFNUMBERQUALIFIER: '',
            REF_REFNUMBER: '',
            PAYMENTDESCRIPTION: '',
            DAT_REFNUMBERQUALIFIER: '',
            DAT_REFNUMBER: '',
            AMOUNTTOADJ: '',
            ADJREASONCODE: '',
            ADJDESCRIPTION: '',
        });
    },

    deleteGrandChild(model) {
        const self = this;

        const options = {
            success() {
                self.trigger('grandChildDeleteSuccess');
            },

            error() {
                self.trigger('grandChildDeleteError');
            },

            model,
        };

        this.model.deleteGrandChild(options);
    },

    savePrime() {
        const self = this;
        const componentGridCollections = {};

        util.each(self.gridComponentViews, (value, key) => {
            componentGridCollections[key] = value.wrapper.rows.models;
        });

        self.model.componentGridCollections = componentGridCollections;

        self.compileLockedFields();

        this.model.save(
            {},
            {
                success(model, confirmResponse) {
                    if (self.viewType === 'modal') {
                        self.trigger('save');
                    } else if (self.overrides && self.overrides.saveSuccessOverride) {
                        self.overrides.saveSuccessOverride();
                    } else if (confirmResponse.resultType === 'WARNING') {
                        const [result] = confirmResponse.confirms.confirmResults;
                        Dialog.custom(new WarningDialog({
                            model,
                            methodName: result.warningAction,
                            confirms: confirmResponse.confirms,
                            updateCount: result.updateCount,
                            entryMethod: result.entryMethod,
                            keyValue: result.keyValue,
                        }));
                    } else if (self.hideButtons === true) {
                        self.trigger('modelAction:success', confirmResponse);
                    } else {
                        Dialog.alert(locale.get('common.save.success'));
                        self.appBus.trigger((`${self.context.productCode}-changed`));
                        self.model.setupFromData();
                    }
                },

                error(model) {
                    const errors = model.error.message.join(', ');
                    // if a grid is present AND has a non-bulk standard error code
                    // we will check for negative amounts and sort the grid accordingly
                    if (self.batchChildGridView && model.error.errorCode
                        === constants.GRID_UPDATE.CODES.STANDARD_SAVE_ERROR) {
                        /*
                         * HACK: Originally this check was done by error code, but the
                         * server only sends a single error code (20003) in the response
                         * object which simply means "your action failed". Currently the
                         * only way to determine a negative amount error is to scan
                         * through each (localized) error message in each action result
                         * for the expected value. This match will likely fail for negative
                         * amount messages not localized to "en_US". If/When this fails,
                         * the only fallout is that the child grid will not automatically
                         * sort by amount. A better solution would be to enhance the server
                         * reponse object to include numeric or non-localized codes along
                         * with each localized string message and then check against those
                         * codes for the expected error condition.
                         */
                        const hasNegativeError = model.error.confirms.confirmResults.some(result => result.messages.some(msg => (msg.toLowerCase().indexOf('amount') >= 0
                                && msg.toLowerCase().indexOf('negative') >= 0)));
                        if (hasNegativeError) {
                            self.batchChildGridView.sortGrid('asc', 'AMOUNT', 1);
                        }
                    }

                    if (self.viewType === 'modal') {
                        $('#error-message').text(errors).show();
                        $('.modal-body').animate({
                            scrollTop: 0,
                        }, 100);
                    } else if (model.error.resultType === 'WARNING') {
                        // 514 error code indicates to Show consumer disclosure
                        if (model.error.errorCode === 514) {
                            self.viewAcceptDisclosure(model);
                        } else if (model.error.errorCode === 540) {
                            self.processDuplicatePayment(model);
                        } else if (model.error.errorCode === 542) {
                            self.processBeneficiaryUpdatedApprovalWarning(model);
                        } else if (model.error.errorCode === 543) {
                            self.processBeneficiaryUpdatedWarning(model);
                        } else {
                            // 551 error code indicates Balance Check Auto Approve Warning
                            if (model.error.errorCode === 551) {
                                model.unset('_saveWithWarning');
                            }
                            Dialog.custom(new WarningDialog({
                                model,
                                methodName: 'SAVE',
                                confirms: model.error.confirms,
                            }));
                        }
                    } else if (self.hideButtons === true) {
                        self.trigger('modelAction:error', self.model);
                    } else {
                        Dialog.alert(errors, 'ERROR');
                    }
                },
            },
        );
    },

    /**
     * Toggle the action buttons
     * @param {boolean} state
     */
    disableButtons(state) {
        // TODO: can we put this in the ui hash
        domUtil.setDisabled($('div.widget-action-btn-group button'), state);
        if (!state) {
            // if the state is false, then we dont want to disableButtons any more,
            // therefore no longer in valid save state
            this.isSaveAction = false;
        }
    },

    /**
     * Display the error and enable buttons after a failed save attempt.
     * @param {string} msg
     */
    rejectSave(msg) {
        Dialog.alert(msg, 'ERROR');
        this.disableButtons(false);
    },

    save() {
        this.disableButtons(true);
        // Map isReadyToSave calls at save time, or the readyState may be stale.
        const widgetReadyPromises = util.map(this.allWidgets, widget => widget.isReadyToSave());

        Promise.all(widgetReadyPromises)
            .then(this.realSave.bind(this), this.rejectSave.bind(this));
    },

    /**
     * @description returns a list of non empty bene widget fields
     *              this function should only be called for payments/templates
     * that use the bene widget
     * @returns {Array} A list of non-empty bene widget fields
     */
    getNonEmptyChildrenFields() {
        if (!this.childView || !this.childView.model || this.state === 'reverse') {
            return null;
        }
        const { model } = this.childView;
        const fieldInfo = model.jsonData.fieldInfoList;
        let fields = util.chain(this.childView.blocks)
            .map(blk => blk.groups)
            .flatten()
            .map((grp) => {
                if (grp.subgroups) {
                    return grp.subgroups;
                }
                return grp;
            })
            .flatten()
            .map(grp => grp.fields)
            .flatten()
            .filter(fld => (fld ? fld.visible : false))
            .filter(fld => !util.isEmpty(model.get(fld.name)))
            .filter((fld) => {
                let retVal = true;
                // if the field is a combo select or a combo and it only has 1 item
                // in it's list, then dont count this as non-empty
                const fInfo = util.findWhere(
                    fieldInfo,
                    {
                        name: fld.name,
                    },
                );
                const uiType = (fInfo) ? fInfo.fieldUIType : '';
                if (uiType === 'COMBOSELECT' && fInfo.choices && fInfo.choices.length === 1) {
                    retVal = false;
                } else if (uiType === 'COMBOBOX' && this.childView.comboCollections && this.childView.comboCollections[fld.name].length === 1) {
                    retVal = false;
                }

                return retVal;
            })
            // PCM-5587 - Issue on re-submit with UDF fields on modify mode
            .filter(fld => !(fld && fld.name.indexOf('USERDEFINED') > -1))
            .value();

        // remove fields that are deemed auto-populated and
        // not critical to determining validity of bene widget
        if (this.childView.beneAutoPopulatedFields) {
            fields = fields
                .filter(fld => !this.childView.beneAutoPopulatedFields.includes(fld.name));
        }

        return fields;
    },

    realSave() {
        const self = this;
        const childFields = this.getNonEmptyChildrenFields();
        const validBeneWidget = this.childView
            && this.useBeneWidget
            && childFields
            && childFields.length > 0;
        let enableButtons = false;

        if ((this.childView && this.childView.model && this.batchChildGridView === null)
            || validBeneWidget) {
            self.childView.model.set('BATCHSEQNUM', self.model.get('BATCHSEQNUM'));

            if (this.childView.model.isValid() && this.model.isValid()) {
                // save the grand child first
                if (self.isGrandChildDirty()) {
                    /*
                     * When a grandChild save fails, this listern has already been added,
                     * so before adding the listener again, remove it, to make sure that we
                     * don't have multiple callbacks firing
                     */
                    self.childView.stopListening(self.childView, 'grandChildSaveSuccess');
                    self.childView.listenToOnce(self.childView, 'grandChildSaveSuccess', () => {
                        self.childView.model.save(
                            {},
                            {
                                success() {
                                    self.savePrime(self.model);
                                },

                                error(model) {
                                    self.trigger('modelAction:error', model);
                                },
                            },
                        );
                    });

                    self.childView.saveGrandChild();
                } else {
                    this.childView.model.save(
                        {},
                        {
                            success() {
                                if (self.beneWidget && self.batchChildGridView
                                        && self.batchChildGridView.gridView
                                        && self.batchChildGridView.gridView.wrapper) {
                                    /*
                                     * If the child save was successful then clear out the bene
                                     * form and refresh the grid. This is necessary to keep the
                                     * screen up to date in case the parent save fails and the
                                     * user remains on the screen.
                                     */
                                    self.beneWidget.model.childNumber += 1;
                                    self.beneWidget.clearBeneficiaryForm();
                                    self.batchChildGridView.gridView.wrapper
                                        .refreshGridData(false, false, true).then(() => {
                                            self.savePrime(self.model);
                                        });
                                } else {
                                    self.savePrime(self.model);
                                }
                            },

                            error(model) {
                                self.trigger('modelAction:error', model);
                                self.disableButtons(false);
                            },
                        },
                    );
                }
            } else {
                if (!this.model.isValid()) {
                    this.model.trigger('invalid');
                    enableButtons = true;
                }
                if (!this.childView.model.isValid()) {
                    this.childView.model.trigger('invalid');
                    enableButtons = true;
                    // NH-49189, the parent model needs to be validated to show the
                    // mandatory errors even when the child model is invalid.
                    // removing the else to validate both the child and parent models.
                }
                if (enableButtons) {
                    this.disableButtons(false);
                }
            }
        } else {
            this.savePrime();
        }
    },

    destroy() {
        const self = this;

        this.model.destroy({
            success() {
                if (self.viewType === 'modal') {
                    self.trigger('destroy');
                } else if (self.overrides && self.overrides.destroySuccessOverride) {
                    self.overrides.destroySuccessOverride();
                } else if (self.hideButtons === true) {
                    self.trigger('destroy:success', self.model);
                } else {
                    Dialog.alert(locale.get('common.delete.success'));
                    self.appBus.trigger((`${self.context.productCode}-changed`));
                    self.model.setupFromData();
                }
            },
        });
    },

    repair() {
        const self = this;

        this.model.repair({
            success(modelParam, confirmResponse) {
                const model = modelParam;
                // Some REPAIR responses go down the error path, others (ACH) come here.
                // This allows us to treat them all consistently in the duplicate dialog.
                model.error = confirmResponse;

                if (self.viewType === 'modal') {
                    self.trigger('save');
                } else if (self.overrides && self.overrides.saveSuccessOverride) {
                    self.overrides.saveSuccessOverride();
                } else if (self.isDuplicatePaymentWarning(model)) {
                    self.processDuplicatePayment(model, confirmResponse);
                } else if (confirmResponse.resultType === 'WARNING') {
                    const [confirmResult] = confirmResponse.confirms.confirmResults;

                    if (!confirmResult.warningAction) {
                        confirmResult.warningAction = 'REPAIR';
                    }

                    Dialog.custom(new WarningDialog({
                        model,
                        methodName: confirmResult.warningAction,
                        confirms: confirmResponse.confirms,
                        updateCount: confirmResult.updateCount,
                        entryMethod: confirmResult.entryMethod,
                        keyValue: confirmResult.keyValue,
                    }));
                } else if (self.hideButtons === true) {
                    self.trigger('modelAction:success', confirmResponse);
                } else {
                    Dialog.alert(locale.get('common.save.success'));
                    self.appBus.trigger((`${self.context.productCode}-changed`));
                    self.model.setupFromData();
                }
            },

            error(model) {
                const errors = model.error.message.join(', ');

                if (self.viewType === 'modal') {
                    $('#error-message').text(errors).show();
                    $('.modal-body').animate({
                        scrollTop: 0,
                    }, 100);
                } else if (self.isDuplicatePaymentWarning(model)) {
                    self.processDuplicatePayment(model);
                } else if (model.error.resultType === 'WARNING') {
                    Dialog.custom(new WarningDialog({
                        model,
                        methodName: 'SAVE',
                        confirms: model.error.confirms,
                    }));
                } else if (self.hideButtons === true) {
                    self.trigger('modelAction:error', self.model);
                } else {
                    Dialog.alert(errors, 'ERROR');
                }
            },
        });
    },

    approve() {
        const self = this;

        this.model.approve({
            success() {
                if (self.viewType === 'modal') {
                    self.trigger('approve');
                } else if (self.overrides && self.overrides.approveSuccessOverride) {
                    self.overrides.approveSuccessOverride();
                } else if (self.hideButtons === true) {
                    self.trigger('approve:success', self.model);
                } else {
                    Dialog.alert(locale.get('common.approved.success'));
                    self.appBus.trigger((`${self.context.productCode}-changed`));
                    self.model.setupFromData();
                }
            },
        });
    },

    reject() {
        const self = this;

        this.model.reject({
            success() {
                if (self.viewType === 'modal') {
                    self.trigger('reject');
                } else if (self.overrides && self.overrides.approveSuccessOverride) {
                    self.overrides.approveSuccessOverride();
                } else if (self.hideButtons === true) {
                    self.trigger('reject:success', self.model);
                } else {
                    Dialog.alert(locale.get('common.rejected.success'));
                    self.appBus.trigger((`${self.context.productCode}-changed`));
                    self.model.setupFromData();
                }
            },
        });
    },

    cancel() {
        if (this.viewType === 'modal') {
            this.trigger('cancel');
        } else if (this.overrides && this.overrides.cancelOverride) {
            this.overrides.cancelOverride();
        }
    },

    hasEntitlement(type) {
        return !!(this.entitlements[type] && this.entitlements[type] === true);
    },

    simpleOperators() {
        const ops = [{
            operator: 'EQ',
            title: locale.get('common.equal'),
        }, {
            operator: 'BETWEEN',
            title: locale.get('common.between'),
        }];
        return (ops);
    },

    /**
     * Operators for new FilterType SimpleNumeric2 - EQ, LTEQ, GTEQ, BETWEEN
     */
    simpleOperatorsExt() {
        const ops = [{
            operator: 'EQ',
            title: locale.get('common.equal'),
        }, {
            operator: 'LTEQ',
            title: locale.get('common.less.or.equal'),
        }, {
            operator: 'GTEQ',
            title: locale.get('common.greater.or.equal'),
        }, {
            operator: 'BETWEEN',
            title: locale.get('common.between'),
        }];
        return (ops);
    },

    /**
     * Operators for new FilterType SimpleNumeric2 - EQ, LT, LTEQ, GT, GTEQ, BETWEEN.
     */
    operatorsWithoutIn() {
        return [{
            operator: 'EQ',
            title: locale.get('common.equal'),
        }, {
            operator: 'LT',
            title: locale.get('common.less'),
        }, {
            operator: 'LTEQ',
            title: locale.get('common.less.or.equal'),
        }, {
            operator: 'GT',
            title: locale.get('common.greater'),
        }, {
            operator: 'GTEQ',
            title: locale.get('common.greater.or.equal'),
        }, {
            operator: 'BETWEEN',
            title: locale.get('common.between'),
        }];
    },

    operators() {
        return [{
            operator: 'EQ',
            title: locale.get('common.equal'),
        }, {
            operator: 'LT',
            title: locale.get('common.less'),
        }, {
            operator: 'LTEQ',
            title: locale.get('common.less.or.equal'),
        }, {
            operator: 'GT',
            title: locale.get('common.greater'),
        }, {
            operator: 'GTEQ',
            title: locale.get('common.greater.or.equal'),
        }, {
            operator: 'BETWEEN',
            title: locale.get('common.between'),
        }, {
            operator: 'IN',
            title: locale.get('common.in'),
        }];
    },

    /**
     * Function made available to templateHelpers to set the selected value.
     * TODO: This seems cumbersome. Possible refactor target.
     * @param {string} op - Operator
     * @return {string}
     */
    isOpSelected(op) {
        return this.model && op === this.model.get('equality') ? 'selected' : '';
    },

    handleOperatorsOnChange(evt) {
        // if value is BETWEEN, then display second operand value
        if (evt && evt.target && evt.target.selectedIndex !== -1) {
            const { target } = evt;
            const op = target.options[target.selectedIndex].value;
            const id = target.name.replace('-equality', '');
            const $firstOperand = $(`#${id}`);
            const $secondOperand = $(`#${id}2`);
            const isBetween = op === 'BETWEEN';

            // Hide second operand if not the BETWEEN operator
            $secondOperand.toggleClass('hidden', !isBetween);
            if (isBetween) {
                /**
                 * Store the current placeholder in prevPlaceholder,
                 * which can be used for other operators (LT, GT, EQ).
                 * Update the placeholder to 'From' when the operator is BETWEEN
                 */
                this.prevPlaceholder = $firstOperand.attr('placeholder');
                $firstOperand.attr('placeholder', locale.get('title.from'));
            } else {
                $firstOperand.attr('placeholder', this.prevPlaceholder || '');
            }
            // set "between" class identifier on operand filter container when applicable
            $(target).parent().next('.filter-search-wrapper').toggleClass('is-between', isBetween);
        }
    },

    /**
     * Correctly updates the lock icon state upon when the user clicks it
     * @param  {Event} e  Jquery click handler event
     */
    toggleLock(e) {
        if (this.state === 'view') {
            return;
        }
        PaymentUtil.toggleLock(e, this.model, this.parentModel);
    },

    /**
     * Evaluates an amount and determines if it's valid or not
     * @param  {String} amount  the amount to be evaluated
     * @return {boolean} to indicate whether or not the amount passed is valid
     */
    verifyValidAmount(amount) {
        return PaymentUtil.verifyValidAmount(amount);
    },

    /**
     * Correctly sets the lock icons disabled state on the amount input change
     * @param  {Event} e  Jquery change handler event
     */
    changeAmount(e) {
        PaymentUtil.changeAmount(e);
    },

    /**
     * Retrieve all locked inputs and store their names
     */
    compileLockedFields() {
        PaymentUtil.compileLockedFields(this);
    },

    templateHelpers() {
        return templateHelpers.helpers(this);
    },

    openDialog(view) {
        // TODO: Fix the workflows after R3.1 so we don't need this awful hack.
        if (Dialog.activeModal) {
            const originalModalBody = Dialog.activeModal.bodyView;
            const originalModalButtons = Dialog.activeModal.buttons;

            const restoreDialog = function () {
                Dialog.activeModal.bodyView = originalModalBody;
                Dialog.activeModal.buttons = originalModalButtons;
                Dialog.activeModal.render();
            };

            Dialog.activeModal.setBody(view);
            Dialog.activeModal.buttons = [{
                callback: restoreDialog,
                className: 'btn btn-primary',
                text: locale.get('button.back'),
            }];
            Dialog.activeModal.render();
            view.$('[data-action="cancel"]').remove();
        } else {
            Dialog.open(view);
        }
    },
});
export default MetaDrivenForm;
