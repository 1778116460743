import ItemView from 'system/gluOverride/itemView';
import MenuView from 'system/gluOverride/menu/menuView';
import dialog from '@glu/dialog';
import ChangePassword from 'system/password/views/change';
import userInfo from 'etc/userInfo';
import locale from '@glu/locale';
import Formatter from 'system/utilities/format';
import http from '@glu/core/src/http';
import services from 'services';
import constants from 'app/administration/constants';
import util from '@glu/core/src/util';
import configuration from 'system/configuration';
import store from 'system/utilities/cache';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import navigationUtil from 'system/navigation/util';
import tmpl from './userMenu.hbs';

export default MenuView.extend({
    template: tmpl,
    className: 'userMenu-container',
    dynamicLinks: [],
    showLogout: false,
    showHelp: false,
    showPasswordChange: false,
    showAskQuestion: false,
    showUserSettings: false,
    showLogoutMenuIcon: false,
    showHelpMenuIcon: false,
    showPasswordChangeMenuIcon: false,
    showUserSettingsMenuIcon: false,
    showAskQuestionMenuIcon: false,
    showAskQuestionData: {},

    attributes: {
        'data-qa': 'userMenu-container',
    },

    ui: {
        ...MenuView.prototype.ui,
        $navbar: 'nav',
        $links: 'nav a[aria-controls="main"]',
        $accountInfo: '[data-hook="getAccountInfo"]',
        $userName: '[data-hook="getUserName"]',
        $lastLoginValue: '[data-hook="getLastLoginValue"]',
        $lastLoginLabel: '[data-hook="getLastLoginLabel"]',
        $userMenuLabel: '#userMenu-label',
    },

    // MenuView does not contain these definitions, so pulling them from ItemView
    setHasLoadedRequiredData: ItemView.prototype.setHasLoadedRequiredData,
    hasLoadedRequiredData: ItemView.prototype.hasLoadedRequiredData,

    templateHelpers() {
        const self = this;
        return {
            appRoot() {
                return configuration.appRoot;
            },

            hasChangePassword() {
                return self.showPasswordChange;
            },

            // PCM-570
            hasSecurityQuestion() {
                return self.showSecurityQuestion;
            },
            // PCM-570

            hasUserSettings() {
                return self.showUserSettings;
            },

            hasAskQuestion() {
                return self.showAskQuestion;
            },

            hasHelp() {
                return self.showHelp;
            },

            hasLogoff() {
                return self.showLogout;
            },

            hasChangePasswordMenuIcon() {
                return self.showPasswordChangeMenuIcon;
            },

            hasAskQuestionMenuIcon() {
                return self.showAskQuestionMenuIcon;
            },

            hasUserSettingsMenuIcon() {
                return self.showUserSettingsMenuIcon;
            },

            hasHelpMenuIcon() {
                return self.showHelpMenuIcon;
            },

            hasLogoffMenuIcon() {
                return self.showLogoutMenuIcon;
            },

            hasAskQuestionLabel() {
                return self.showAskQuestionData.label;
            },
            hasAskQuestionURL() {
                return self.showAskQuestionData.url;
            },

            sanitizeKey(key) {
                /*
                 * replace all forward slash characters with an underscore character so we
                 * can correction
                 * build css classes and id's from urls with multiple slash characters.
                 * ie. when passing params
                 */
                if (key) {
                    // replace all forward and back slash with underscore
                    return key.replace(/[/\\\\]/g, '_');
                }
                return undefined;
            },

            dynamicLinks() {
                return self.getDynamicLinks();
            },

            isLearningLink() {
                return this.appResourceName === 'Learning Center';
            },
            isMyProfileLink() {
                return this.appResourceName === 'My Profile';
            },
            isLegalLink() {
                return this.appResourceName === 'Terms & Conditions and Use';
            },
            isResourceLink() {
                return this.appResourceName === 'Resource Center';
            },
        };
    },

    /**
     * @method processUserMenuModel
     * @param {object} menuModel -  menu model returned from the Rest MenuService
     *
     *  Process the UserMenu object within the incoming menuModel to determine whether -
     *  1. There are any dynamic links to set up.
     *  2. To hide/display the Help menu
     *  3. To hide/display the change password menu
     *  4. To hide/display the My Settings menu
     *  5. To hide/display the logoff menu
     */
    processUserMenuModel(menuModel) {
        const modules = menuModel.get('modules');

        if (!modules || modules.length === 0) {
            return;
        }

        util.chain(modules[0].components)
            .filter(component => component.key === 'USERMENU' || component.key === 'SMBUSERMENU')
            .each((component) => {
                util.each(component.actions, (action) => {
                    if (action.displayOrder > -1) {
                        /*
                         * established menu codes associated to the menu items logout,
                         * help, change-password
                         */
                        if (action.menuCode === '_ADMIN_LOGT_TM') {
                            this.showLogout = true;
                            if (action.displayIcon > 0) {
                                this.showLogoutMenuIcon = true;
                            }
                        } else if (action.menuCode === '_ADMIN_HELP_TM') {
                            this.showHelp = true;
                            if (action.displayIcon > 0) {
                                this.showHelpMenuIcon = true;
                            }
                        } else if (action.menuCode === '_ADMIN_PWDCH_TM') {
                            this.showPasswordChange = userInfo.get('changePasswordMenuVisible');
                            if (action.displayIcon > 0) {
                                this.showPasswordChangeMenuIcon = userInfo.get('changePasswordMenuVisible');
                            }
                        } else if (action.menuCode === 'VIEW_SEC_QUES') { // PCM-570
                            this.showSecurityQuestion = true;
                            if (action.displayIcon > 0) {
                                this.showSecurityQuestionMenuIcon = true;
                            }
                        } else if (action.menuCode === 'USER_MY_SETTINGS') {
                            if (action.displayIcon > 0) {
                                this.showUserSettingsMenuIcon = true;
                            }
                        }
                        // any dynamic link has this product code
                        if (action.productCode === 'DYNLINK') {
                            const dynLinkObj = {
                                key: action.key,
                                requestMappings: `${action.menuCatergory.toUpperCase()}/${action.requestMappings}`,
                                appResourceName: action.name,
                            };
                            this.dynamicLinks.push(dynLinkObj);
                        }
                    }
                });
            });
    },

    getDynamicLinks() {
        return this.dynamicLinks;
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            /**
             * need to defer the loadRequiredData, which makes a service call
             *  because in SMB the (first) request falls on the floor
             */
            util.defer(() => {
                this.loadRequiredData();
            });
        } else {
            this.renderUserInfo();
        }
    },

    /**
     * check entitlements for access to the user settings widget
     */
    loadRequiredData() {
        const userSettingPromise = new Promise((resolve, reject) => {
            const url = services.generateUrl('accessService/hasAccess');
            http.post(url, constants.USERSETTINGSMENUREQUESTCONTEXT, (response) => {
                resolve(response);
            }, (e) => {
                reject(e);
            });
        });
        userSettingPromise.then((result) => {
            this.showUserSettings = result;
            this.setHasLoadedRequiredData(true);
            this.render();
        });
    },

    renderUserInfo() {
        const userName = userInfo.attributes.name;

        this.ui.$userName.text(userName);
        if (!util.isNullOrUndefined(userInfo.get('lastLogin'))) {
            this.ui.$lastLoginLabel.removeClass('hidden');
            this.ui.$lastLoginValue.text(`${Formatter.formatDate(userInfo.get('lastLogin'))} ${Formatter.formatTime(userInfo.get('lastLogin'))}`);
        }

        if (userInfo.attributes.simulatedSession) {
            this.ui.$userName.text(`${userName} ${locale.get('system.simulated')}`);
        }

        this.ui.$userMenuLabel.attr('aria-label', (i, val) => `${val} ${userName}`);
    },

    changePassword() {
        dialog.open(new ChangePassword({
            showSuccessMsg: true,
        }));
    },

    userSettings() {
        this.navigateTo('ADMINSTRATION/alertsCenter/mySettings');
    },

    // PCM-570
    securityQuestion() {
        this.navigateTo('SETUP/utilities/questions');
    },
    // PCM-570

    /**
     * @method getHelpPage
     * @description - returns the help page in cache associated with the current view
     */
    getHelpPage() {
        return store.get('helpPage');
    },

    clientHelp() {
        navigationUtil.clientHelp();
    },

    logOut(event) {
        event.preventDefault();
        if (userInfo.attributes.simulatedSession) {
            const simHref = serverConfigParams.get('AdminAppURL');
            /*
             * NH-109423 do not change the href of the opener as
             * this is refreshing the portal window
             */
            window.location.href = simHref;
        } else {
            this.navigateTo('logout');
        }
    },
});
