import util from '@glu/core/src/util';
import locale from '@glu/locale';
import services from 'services';
import http from '@glu/core/src/http';
import browser from '@glu/core/src/browser';
import { appBus } from '@glu/core';
import Dialog from '@glu/dialog';
import userInfo from 'etc/userInfo';
import configuration from 'system/configuration';
import moment from 'moment';
import constants from 'app/administration/constants';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import dateUtil from 'common/util/dateUtil';
import store from 'system/utilities/cache';

export default function (form, initialState) {
    const formState = form.formView.state;
    const usergroup = form.field('USERGROUP');
    const userId = form.field('USERID');
    const userName = form.field('USERNAME');
    const firstName = form.field('FIRSTNAME');
    const lastName = form.field('LASTNAME');
    const ssoId = form.field('SSOID');
    const status = form.field('STATUS_DESCRIPTION');
    const password = form.field('PASSWORD');
    const confirmPassword = form.field('CONFIRMPASSWORD');
    const addAddressLine = form.field('ADDADDRESSLINE');
    const clearOTP = form.field('CLEAROTP');
    const clearSecQuest = form.field('CLEARSECURITYQUESTIONS');
    const copyExistingUser = form.field('COPYEXISTINGUSER');
    const mobile = form.field('MOBILE');
    const mobileHybrid = form.field('USINGMOBILEHYBRIDAPP');
    const registerMobileLink = form.field('REGISTERMOBILEDEVICE');
    const revokeMobileLink = form.field('REVOKEMOBILEDEVICE');
    const optCheckField = form.field('OPTUISECURITYCHECK');
    const optReasonField = form.field('OPTUISECURITYCHECKREASON');
    const alternateUserId = form.field('ALTERNATEUSERID');
    const tokenField = form.field('TOKENSERIALNUMBER');
    const phoneField = form.field('PHONENUMBER');
    const mobileField = form.field('MOBILEPHONENUMBER');
    const faxField = form.field('FAXNUMBER');
    const emailAddress = form.field('EMAILADDRESS');
    const challengeMethodField = form.field('CHALLENGEMETHOD');
    const legalAdmin = form.field('LEGALADMIN');
    const activationDate = form.field('ACTIVATIONDATE');
    const cannotGrantBeyondOwn = form.field('CANNOTGRANTBEYONDOWN');
    const ssoUserId = form.field('SSOUSRID');
    const allowPasswordLogin = form.field('ALLOWPASSWORD_LOGIN');
    const allowSSOUser = form.field('ALLOWSSO_USER');
    const ssoStatus = form.field('SSO_STATUS');
    const addressLine1 = form.field('ADDRESSLINE1');
    const city = form.field('CITY');
    const localeField = form.field('LOCALE');
    const stateField = form.field('STATE');
    const postalCode = form.field('POSTALCODE');
    let address;
    let addressCount = 2;
    let fieldName = 'ADDRESSLINE2';
    const { model } = form.formView;
    const userCompany = form.formView.userGroupModel;
    const enableActivationDate = serverConfigParams.get('enableActivationDate');
    const allowSSO = userCompany.get('allowSSO');
    //    const sso = serverConfigParams.get('ClientAppOnSSO');
    const showTokenSerialNum = serverConfigParams.get('showTokenSerialNum') === 'true';
    const OTP_CHALLENGE_METHOD = '0';
    const VASCO_CHALLENGE_METHOD = '6';
    const SYMANTEC_VIP_CHALLENGE_METHOD = '8';
    // the entitlements are stored in 'uce-load' for both uce & role users
    const userStore = store.get('uce-load');
    let userEntitlements = userStore ? userStore.entitlement : {};
    userEntitlements = userEntitlements || {};
    let isTxClient = false;

    const showActivationDate = function () {
        if (enableActivationDate !== 'Y') {
            activationDate.shouldBeHidden();
        } else if (enableActivationDate === 'Y' && model.get('APPROVEDBYTIMESTAMP') && model.get('ACTIVATIONDATE')) {
            if (moment.duration(dateUtil.getMoment(model.get('ACTIVATIONDATE')).diff(dateUtil.getMoment())).asDays() <= 0) {
                activationDate.shouldBeRequired(false);
                activationDate.shouldBeReadOnly(true);
            } else {
                activationDate.shouldBeReadOnly(false);
                activationDate.shouldBeRequired(true);
            }
        } else {
            activationDate.shouldBeReadOnly(false);
            activationDate.shouldBeRequired(true);
        }
    };

    /**
     * remove validator for PASSWORD & CONFIRMPASSWORD and clear any remaining
     * inline-error messages
     */
    const clearPasswordValidation = () => {
        password.removeValidator('exists');
        password.removeValidator('sameValue').$el.parent().removeClass('required');
        confirmPassword.removeValidator('exists');
        confirmPassword.removeValidator('sameValue').$el.parent().removeClass('required');
        // remove any inline errors
        form.formView.$('[data-validate="PASSWORD"]').text('').closest('.has-error').removeClass('has-error');
        form.formView.$('[data-validate="CONFIRMPASSWORD"]').text('').closest('.has-error').removeClass('has-error');
    };

    /**
     * this function is used with PASSWORD and CONFIRMPASSWORD
     * if these fields are the same and one has a lingering in-line error message,
     * clear it out
     * @param field2check
     */
    const checkSameValuePassword = (field2check) => {
        const $validator = form.formView.$(`[data-validate="${field2check}"]`);
        if ($validator.closest('.has-error').length > 0 && (model.get('CONFIRMPASSWORD') === model.get('PASSWORD'))) {
            $validator.text('').closest('.has-error').removeClass('has-error');
        }
    };

    /**
     * Toggle model validation and required class in the DOM for password fields
     * @param {boolean} required
     */
    const requirePassword = (required) => {
        if (required && !isTxClient) {
            password.setValidator(password.$el, 'exists', true);
            password.shouldMatchField(confirmPassword).$el.parent().addClass('required');
            confirmPassword.setValidator(confirmPassword.$el, 'exists', true);
            confirmPassword.shouldMatchField(password).$el.parent().addClass('required');
        } else {
            password.setValidator(password.$el, 'exists', false);
            password.shouldMatchField(confirmPassword).$el.parent().removeClass('required');
            confirmPassword.setValidator(confirmPassword.$el, 'exists', false);
            confirmPassword.shouldMatchField(password).$el.parent().removeClass('required');
        }
    };

    /**
     * Show/hide password section base on the parameter
     * @param {boolean} show
     */
    const showPasswordFields = (show) => {
        if (!show) {
            requirePassword(show);
        }
        password.$el.closest('.section.section-container').toggle(show);
    };

    /**
     * Get the email password reset config paramaeter as a boolean
     * @return {boolean}
     */
    const getEmailPasswordConfig = () => {
        const emailResetPassword = serverConfigParams.get('EmailResetPassword');
        return emailResetPassword && emailResetPassword.toUpperCase() === 'TRUE';
    };

    /**
     * if client, check userInfo isUce
     * if admin, check company of the user being added/modified/viewed
     */
    const isUserGroupUce = () => ((configuration.isAdmin() && (userCompany.get('isUce'))) || (!configuration.isAdmin() && userInfo.isUce()));

    if (this.formView.context.actionContext.subType === 'UPDSET') {
        return;
    }

    userCompany.attributes.mapDataList.forEach((mapData) => {
        if (mapData.toField === 'TREASURYINTEGRATED') { isTxClient = mapData.value === '1'; }
    });

    if (isTxClient) {
        status.shouldBeHidden();
        ssoId.shouldBeVisibleWhen(false);
        userName.shouldBeHidden();
        userId.shouldBeReadOnly(true);
        emailAddress.shouldBeHidden();
        phoneField.shouldBeHidden();
        mobileField.shouldBeHidden();
        faxField.shouldBeHidden();
        usergroup.shouldBeHidden();
        firstName.shouldBeHidden();
        lastName.shouldBeHidden();
        addressLine1.shouldBeHidden();
        city.shouldBeHidden();
        localeField.shouldBeHidden();
        showPasswordFields(false);
        requirePassword(false);
        stateField.shouldBeHidden();
        postalCode.shouldBeHidden();
        addAddressLine.shouldBeHidden();
        form.formView.$('.adminSettingsContainerGroup').closest('.section-container').hide();
        form.formView.$('.PTXGroup_user').closest('.section-container').children('.section-header').hide();
        form.formView.$('.copyExistingUserPermGroup').closest('.section-container').hide();
        // Hide the audit section
        util.each(['ENTEREDBYTIMESTAMP', 'MODIFIEDBYGROUPTIMESTAMP'], (name) => {
            form.field(name).$el.closest('.section').hide();
        });
    } else {
        ssoId.shouldBeVisibleWhen(false);
    }

    if (initialState) {
        if (serverConfigParams.get('EnableInternationalPhoneNumbers') === 'true') {
            /*
             * override the PHONE validator and allow up to 20 characters,
             * support at least a +, -, ( and space, and not force a format
             */
            ['PHONENUMBER', 'MOBILEPHONENUMBER', 'FAXNUMBER'].forEach((phoneFieldName) => {
                const oldValidator = model.validators[phoneFieldName];

                if (oldValidator) {
                    model.removeValidator(phoneFieldName);
                    model.addValidator(phoneFieldName, {
                        ...oldValidator,
                        overrideError: 'internationalPhoneNumber',
                        matches: /^[0-9 ()+-]+$/,
                    });
                }
            });
        } else {
            mobileField.$el.inputmask(userInfo.getPhoneFormat());
            phoneField.$el.inputmask(userInfo.getPhoneFormat());
            faxField.$el.inputmask(userInfo.getPhoneFormat());
        }

        if (challengeMethodField && challengeMethodField.$el && challengeMethodField.$el.context
                    && challengeMethodField.$el.context.options
                    && challengeMethodField.$el.context.options.length <= 2) {
            challengeMethodField.shouldBeReadOnly(true);
        }

        if (model.get('OFX_FLAG') === 'false') {
            if (formState === 'modify') {
                form.formView.$('#ALTERNATEUSERID').hide();
            } else {
                alternateUserId.shouldBeHidden();
            }
        }

        if (formState === 'insert') {
            usergroup.$el.change();
        }
        usergroup.shouldBeReadOnly(true);

        while (fieldName in model.attributes) {
            address = form.field(fieldName);
            address.shouldBeVisibleWhen(address.isNotEmpty());
            addressCount += 1;
            fieldName = `ADDRESSLINE${addressCount}`;
        }

        addAddressLine.$el.on('click', (e) => {
            e.stopImmediatePropagation();
            const { $el } = addAddressLine;
            const prev = $el.data('prev') || 1;
            const current = prev + 1;
            const next = current + 1;
            const name = `ADDRESSLINE${current}`;
            if (name in model.attributes) {
                form.field(name).shouldBeVisible();
                $el.data('prev', current);
            }

            if (!((`ADDRESSLINE${next}`) in model.attributes)) {
                addAddressLine.shouldBeHidden();
            }
        });

        if (userCompany.get('optUISecurityCheck')) {
            optCheckField.shouldBeHidden();
            optReasonField.shouldBeHidden();
        } else {
            const optUI = model.get('OPTUISECURITYCHECK');

            optReasonField.shouldBeReadOnly(formState === 'insert' || !optUI);
            if (!optUI) {
                optReasonField.setValue('');
            }

            // set disabled status when option changed
            optCheckField.$el.on('change', (e) => {
                const { checked } = e.target;
                optReasonField.shouldBeReadOnly(!checked);
                if (!checked) {
                    optReasonField.setValue('');
                }
            });
        }
        emailAddress.$el.parent().children('.span-popover-trigger').hide();
        if (formState === 'insert') {
            optReasonField.shouldBeReadOnly(true);

            clearOTP.shouldBeHidden();
            clearSecQuest.shouldBeHidden();
            tokenField.shouldBeHidden();

            model.set('TIMEZONEID', userCompany.get('timezone'));
            model.set('LOCALE', userCompany.get('locale'));

            userId.$el.on('blur', () => {
                model.set('USERID', userId.getValue().toUpperCase());
                model.validateField('USERID');
            });

            // Hide the audit section
            util.each(['ENTEREDBYTIMESTAMP', 'MODIFIEDBYGROUPTIMESTAMP'], (name) => {
                form.field(name).$el.closest('.section').hide();
            });

            if (!isTxClient) {
                showPasswordFields(true);
                requirePassword(true);
            }

            if (allowSSO) {
                ssoUserId.shouldBeReadOnly(false);
                allowPasswordLogin.$el.prop('disabled', true);
                allowPasswordLogin.shouldBeReadOnly(true);
                ssoStatus.shouldBeReadOnly(true);
                allowSSOUser.$el.on('change', (e) => {
                    const { checked } = e.target;
                    if (!checked) {
                        allowPasswordLogin.setValue('');
                        allowPasswordLogin.$el.prop('checked', false);
                        allowPasswordLogin.shouldBeReadOnly(true);
                        allowPasswordLogin.$el.prop('disabled', true);
                    } else {
                        allowPasswordLogin.shouldBeReadOnly(false);
                    }
                });
            } else {
                form.field('SSOUSRID').shouldBeHidden();
                form.field('SSO_STATUS').shouldBeHidden();
                form.field('ALLOWSSO_USER').shouldBeHidden();
                form.field('ALLOWSSO_USER').$el.closest('.section').hide();
                form.field('ALLOWPASSWORD_LOGIN').shouldBeHidden();
                form.field('ALLOWPASSWORD_LOGIN').$el.closest('.section').hide();
            }
            status.shouldBeHidden();
            alternateUserId.shouldBeHidden();
            if (!configuration.isAdmin()) {
                legalAdmin.shouldBeHidden();
            }
        }

        if (formState === 'modify') {
            const resetPasswordEntitled = userEntitlements.RESETPW;
            const modifyEntitled = userEntitlements.MODIFY;
            const emailResetPasswordConfig = getEmailPasswordConfig();
            if (model.get('CHALLENGEMETHOD') !== OTP_CHALLENGE_METHOD) {
                clearOTP.shouldBeHidden();
            }

            if (!(model.get('CHALLENGEMETHOD') === VASCO_CHALLENGE_METHOD || model.get('CHALLENGEMETHOD') === SYMANTEC_VIP_CHALLENGE_METHOD)) {
                tokenField.shouldBeHidden();
            }

            /*
             * Confirm that password field starts empty and is optional on 'modify'
             * On Firefox, it will autocomplete password fields after the page loads
             * if the user has saved logins on the browser
             */
            if (browser.firefox) {
                util.delay(() => {
                    password.setValue('');
                    password.shouldBeOptional();
                    password.$el.parent().removeClass('required');
                    // reset the password field
                    password.setValue('');
                    password.$el.trigger('change');
                    confirmPassword.shouldBeOptional();
                    confirmPassword.$el.parent().removeClass('required');
                }, 100);
            }

            userId.shouldBeReadOnly(true);
            status.shouldBeReadOnly(true);
            if (!configuration.isAdmin()) {
                emailAddress.shouldBeReadOnly(model.get('LEGALADMIN') === '1');
                if (model.get('LEGALADMIN') === '1') {
                    emailAddress.$el.parent().children('.span-popover-trigger').show();
                }
                legalAdmin.shouldBeReadOnly(model.get('LEGALADMIN') === '1' || model.get('LEGALADMIN') === '');
            } else {
                legalAdmin.shouldBeReadOnly();
                /**
                 * TODO When adding functionality to client, move this function call out
                 * of the else block which is for admin users
                 */
                // NH-109035 'hybrid' implementation until reset password is done only by email
                showPasswordFields((resetPasswordEntitled && !emailResetPasswordConfig)
                        || (modifyEntitled && resetPasswordEntitled && emailResetPasswordConfig));
            }
        }
        showActivationDate();
        //        if (sso && sso.toLowerCase() === 'true') {
        //            ssoId.shouldBeRequired();
        ssoId.shouldBeVisibleWhen(configuration.isAdmin());
        //            showPasswordFields(false);
        //        } else {
        //            ssoId.shouldBeHidden();
        //        }
        if (allowSSO) {
            ssoUserId.shouldBeReadOnly(false);
            ssoStatus.shouldBeReadOnly(true);
            if (formState === 'modify' && allowSSOUser.isChecked()) {
                allowPasswordLogin.shouldBeReadOnly(false);
            } else if (formState === 'modify') {
                allowPasswordLogin.shouldBeReadOnly(true);
            }
            allowSSOUser.$el.on('change', (e) => {
                const { checked } = e.target;
                if (!checked) {
                    allowPasswordLogin.setValue('');
                    allowPasswordLogin.$el.prop('checked', false);
                    allowPasswordLogin.shouldBeReadOnly(true);
                    allowPasswordLogin.$el.prop('disabled', true);
                } else {
                    allowPasswordLogin.shouldBeReadOnly(false);
                }
            });
        } else {
            form.field('SSOUSRID').shouldBeHidden();
            form.field('SSO_STATUS').shouldBeHidden();
            form.field('ALLOWSSO_USER').shouldBeHidden();
            form.field('ALLOWSSO_USER').$el.closest('.section').hide();
            form.field('ALLOWPASSWORD_LOGIN').shouldBeHidden();
            form.field('ALLOWPASSWORD_LOGIN').$el.closest('.section').hide();
        }

        if (!isUserGroupUce()) {
            copyExistingUser.shouldBeHidden();
            copyExistingUser.hideFieldBlockHeader();
            // if rolebase user is being added/modified hide cannotgrantbeyondown checkbox
            cannotGrantBeyondOwn.shouldBeHidden();
        }

        if (serverConfigParams.get('DisableClientAccountMasking').toUpperCase() === 'TRUE' || !userCompany.get('allowAccountUnmasking')) {
            form.field('ALLOWACCOUNTUNMASKING').shouldBeHidden();
        }

        if (!(serverConfigParams.get('EnablePushNotification').toUpperCase() === 'TRUE' && userCompany.get('enablePushNotifications'))) {
            form.field('ENABLEPUSHNOTIFICATIONS').shouldBeHidden();
        }
        /*
         *
         * Hide the mobileHybrid checkbox for now. This will be implemented
         * when we can separate the mobile app from opening a mobile browser
         */
        mobileHybrid.shouldBeHidden();

        if (!userCompany.get('isMobile')) {
            mobile.shouldBeHidden();
            registerMobileLink.shouldBeHidden();
            revokeMobileLink.shouldBeHidden();
        } else if (!userCompany.get('isMobileDeviceEnabled')) {
            registerMobileLink.shouldBeHidden();
            revokeMobileLink.shouldBeHidden();
        } else {
            registerMobileLink.$el.removeClass('btn-tertiary').addClass('btn-primary');
            registerMobileLink.$el.on('click', function () {
                // call service to trigger MFA flow
                const userObj = {
                    userGroup: model.get('USERGROUP'),
                    userId: model.get('USERID'),
                };

                appBus.once('adminMfa:success', (user) => {
                    if (util.isEqual(user, userObj)) {
                        registerMobileLink.$el.prop('disabled', true);
                        revokeMobileLink.$el.prop('disabled', false);
                        // form binding needs the event
                        mobile.$el.prop('checked', true).trigger('change');
                        mobileHybrid.$el.prop('checked', true).trigger('change');
                    }
                }, this);

                http.post(services.generateUrl('userMaintenance/user/startMobileUserRegistration'), userObj, () => {
                    Dialog.alert(locale.get('administration.mobile.mfa.device.registered'));
                }, (result) => {
                    let msg;
                    if (result.responseJSON.errorCode
                            === constants.MOBILE_REG_CANCEL_ERRORCODE) {
                        msg = locale.get('administration.mobile.device.registration.cancelled');
                    } else {
                        msg = locale.get('administration.mobile.mfa.device.registration.error');
                    }
                    Dialog.alert(msg);
                });
            });

            revokeMobileLink.$el.removeClass('btn-tertiary').addClass('btn-primary');
            revokeMobileLink.$el.on('click', () => {
                const userObj = {
                    userGroup: model.get('USERGROUP'),
                    userId: model.get('USERID'),
                };
                http.post(services.generateUrl('userMaintenance/user/disableMobileForMfa'), userObj, () => {
                    Dialog.alert(locale.get('administration.mobile.mfa.device.revoked'));
                    registerMobileLink.$el.prop('disabled', false);
                    revokeMobileLink.$el.prop('disabled', true);
                });
            });

            registerMobileLink.shouldBeVisible();
            revokeMobileLink.shouldBeVisible();
            if (userCompany.get('isMobileDeviceRegistered') || (!util.isNullOrUndefined(model.get('MOBILEDEVICE_REGISTERED')) && model.get('MOBILEDEVICE_REGISTERED') === '1')) {
                registerMobileLink.$el.prop('disabled', true);
                revokeMobileLink.$el.prop('disabled', false);
            } else {
                registerMobileLink.$el.prop('disabled', false);
                revokeMobileLink.$el.prop('disabled', true);
            }
        }

        model.on('change:CHALLENGEMETHOD', () => {
            if (model.get('CHALLENGEMETHOD') === VASCO_CHALLENGE_METHOD || model.get('CHALLENGEMETHOD') === SYMANTEC_VIP_CHALLENGE_METHOD) {
                tokenField.shouldBeVisible();
            } else if (showTokenSerialNum) {
                tokenField.shouldBeVisible();
            } else {
                tokenField.shouldBeHidden();
            }
            model.set('TOKENSERIALNUMBER', '');
        });

        model.listenTo(model, 'valid:field:PASSWORD', () => {
            /*
             * check if CONFIRMPASSWORD has an error message and matches password, then
             * clear out the message
             */
            checkSameValuePassword('CONFIRMPASSWORD');
        });

        model.listenTo(model, 'valid:field:CONFIRMPASSWORD', () => {
            /*
             * check if PASSWORD has an error message and matches password, then clear
             * out the message
             */
            checkSameValuePassword('PASSWORD');
        });
    } else if (password.getValue().length > 0 || confirmPassword.getValue().length > 0) {
        password.setValidator(password.$el, 'exists', true);
        password.shouldMatchField(confirmPassword).$el.parent().addClass('required');
        confirmPassword.setValidator(confirmPassword.$el, 'exists', true);
        confirmPassword.shouldMatchField(password).$el.parent().addClass('required');
    } else if (formState === 'modify' && (confirmPassword.getValue().length === 0 && password.getValue().length === 0)) {
        // in modify & both password & confirm password are empty, remove validation
        clearPasswordValidation();
    }
}
