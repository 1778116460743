import { appBus } from '@glu/core';
import alert from '@glu/alerts';
import dialog from '@glu/dialog';
import http from '@glu/core/src/http';
import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import $ from 'jquery';
import locale from '@glu/locale';
import number from 'numeral';
import services from 'services';
import userInfo from 'etc/userInfo';
import dateUtil from 'common/util/dateUtil';
import BeneUpdatedWarning from 'common/dynamicPages/views/beneUpdatedApprovalWarning';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import constants from 'common/dynamicPages/api/constants';
import ContextApi from 'common/dynamicPages/api/context';
import ContextModel from 'common/dynamicPages/models/context';
import DataApi from 'common/dynamicPages/api/data';
import domUtil from 'common/util/domUtil';
import DuplicateDialog from 'common/dynamicPages/views/duplicateDialog';
import DynamicReportUtil from 'app/reports/views/dynamicReports';
import errorHandlers from 'system/error/handlers';
import Formatter from 'system/utilities/format';
import gridApi from 'common/dynamicPages/api/grid';
import NeedInfoDialog from 'common/dynamicPages/views/needInfoDialog';
import PageApi from 'common/dynamicPages/api/view';
import PaymentUtil from 'common/util/paymentUtil';
import RejectDialog from 'common/dynamicPages/views/rejectDialog';
import ReportConstants from 'app/reports/constants';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import store from 'system/utilities/cache';
import viewHelper from 'common/dynamicPages/api/viewHelper';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import workspaceHelper from 'common/workspaces/api/helper';
import adminConstants from 'app/administration/constants';
import systemConfig from 'system/configuration';
import loadingTemplate from 'common/templates/loadingPage.hbs';
/**
 * Base view for an MDF single item page
 */
import { createTransformLocaleView } from 'components/TransformLocale/TransformLocaleWrapper';
import template from './entry.hbs';
import { isDeepLinked } from '../../../util/deeplinkUtil';


export default Layout.extend({
    template,
    loadingTemplate,
    className: 'full-page',

    ui: {
        $cancelButton: '.widget-action-btn-group button[name="cancel"]',
        $childViewAmount: '[data-hook="getAmount"]',
        $compSelector: '[data-region="compidnameWidgetRegion"]',
        $footerElements: '[data-hook="footerElements"]',
        $reverseButton: '.widget-action-btn-group button[name="reversesubmit"]',
        $saveButton: '.widget-action-btn-group button[name="save"]',
        $saveDraftButton: '.widget-action-btn-group button[name="savedraft"]',
        $summaryAmount: '[data-field="summary-amount"]',
        $summaryCreditAmount: '[data-field="summary-credit-amount"]',
        $summaryCreditNumber: '[data-bind="TOTALNUMCREDIT"]',
        $summaryDebitAmount: '[data-field="summary-debit-amount"]',
        $summaryDebitNumber: '[data-bind="TOTALNUMDEBIT"]',
        $update: '[name="ENTRYDESC"]',
        $updateCompDiscData: '[name="COMPDISCDATA"]',
        $widgetActionBtnGroup: '[data-hook="getWidgetActionBtnGroup"]',
    },

    events: {
        'blur @ui.$childViewAmount': 'setNewFooterValue',
        'blur @ui.$update': 'onChange',
        'blur @ui.$updateCompDiscData': 'onChange',
        'focus @ui.$childViewAmount': 'getStartAmount',
    },

    initialize(options) {
        this.options = options;
        this.init();
    },

    init() {
        this.lastFragment = this.options.lastFragment;
        this.returnRoute = this.options.returnRoute;
        this.contextDef = this.options.contextDef
            || ContextApi.menuContext.getContext(this.options.context);
        const isTemplate = this.contextDef.functionCode === 'TMPL';
        if (!this.contextDef.serviceName && this.options.serviceName) {
            if (this.options.serviceName.indexOf('/') !== -1) {
                this.contextDef.serviceName = this.options.serviceName;
            } else {
                this.contextDef.serviceName = `${this.options.serviceName}/${this.options.serviceFunc}/${this.options.businessType}`;
            }
        }
        this.mode = this.options.mode || 'view';
        if (this.contextDef && this.mode) {
            this.contextDef.actionMode = this.mode.toUpperCase();
        }
        this.buttonList = [];
        this.contextModel = new ContextModel({
            menuCategory: this.options.menuCategory,
            serviceName: this.options.serviceName,
            serviceFunc: this.options.serviceFunc,
            businessType: this.options.businessType,
            context: this.options.context,
            contextDef: this.contextDef,
        });

        this.contextKey = this.contextModel.getContextKey();
        this.localeKey = this.contextModel.getLocaleKey();

        const { buttonsToHide } = store.get(`${this.contextKey}-actionModel`) || {};
        this.buttonsToHide = buttonsToHide;

        this.viewType = this.options.viewType || 'default';
        this.hideHeader = this.options.hideHeader;

        const contextOverride = store.get(`${this.contextKey}-contextOverride`);
        if (contextOverride) {
            store.set(`${this.contextKey}-contextOverride`, null);
            this.contextDef = contextOverride;
            if (this.contextDef && this.mode) {
                this.contextDef.actionMode = this.mode.toUpperCase();
            }
            this.contextModel.set('contextDef', this.contextDef);
            this.localeKey = this.contextModel.getLocaleKey();
            /**
             * Single Templates (Function Code of TMPL) use the same rest services as
             * Single Payments (Function Code of INST) so here we adjust the locale key to
             * 'template' from 'payment' since Single Templates have their own set of
             * Application Resource keys
             */
            if (isTemplate || this.contextDef.functionCode === 'TMPL' || (this.contextDef.actionContext && this.contextDef.actionContext.functionCode === 'TMPL')) {
                /*
                 * TODO Find another way in the future.
                 *  RFP does not into the convention so it must be overridden.
                 */
                if (this.contextDef.functionCode === 'REQUEST') {
                    this.localeKey = 'requestTemplate.outgoingPaymentRequestTemplates_';
                } else {
                    this.localeKey = this.localeKey.replace('payment', 'template');
                }
            }
        }

        const saveDraftButtonSupported = this.contextDef.enableSaveDraft;
        if (this.mode !== 'insert' && this.mode !== 'import') {
            // NH-141098 - if this.model exists we need to use it (for models and buttons)
            // in [reverse] positive pay details single/multiple viewer.
            // Otherwise keep using this.getTheModel() for ACH, Wire, payments, templates, etc.
            const model = this.model === undefined ? this.getTheModel() : this.model;

            // NH-27619 - SMB Reimburse Override
            if (this.options.reimburse) {
                this.setSmbReimburseAttributes(model);
                store.set(`${this.contextModel.getContextKey()}-actionModel`, model);
            } else {
                this.setEntryDescription(model);
                this.setCompDiscData(model);
            }

            this.tempModel = model;

            /**
             * In some read-only contexts where there is only a Close button
             * the model may not have a context defined, e.g. the admin app's
             * File Import History.
             */
            if (model && model.context) {
                const specificContextDefinition = model.context;
                util.extend(specificContextDefinition, {
                    actionMode: this.mode,
                });

                const listUrl = this.contextModel.getListUrl();
                const SpecificContextModel = ContextModel.extend({
                    getListUrl() {
                        return listUrl;
                    },
                });

                const specificContextModel = new SpecificContextModel({
                    menuCategory: this.options.menuCategory,
                    serviceName: specificContextDefinition.serviceName,
                    serviceFunc: this.options.serviceFunc,
                    businessType: this.options.businessType,
                    context: this.options.context,
                    contextDef: specificContextDefinition,
                });

                this.contextModel = specificContextModel;
                this.contextDef = specificContextDefinition;
                this.localeKey = specificContextModel.getLocaleKey();
                /**
                 * Single Templates (Function Code of TMPL) use the same rest services as
                 * Single Payments (Function Code of INST) so here Adjust the locale key to
                 * 'template' from 'payment' since Single Templates have their own set of
                 * Application Resource keys
                 */
                if (specificContextDefinition.actionData && (specificContextDefinition.actionData.productCode === 'RTGS'
                    || specificContextDefinition.actionData.productCode === 'RTP')
                    && specificContextDefinition.actionData.functionCode === 'TMPL') {
                    this.localeKey = this.localeKey.replace('payment', 'template');
                }
            }

            if (this.mode === 'modify') {
                this.getModifyButtons();
            } else if (this.mode === 'repair') {
                this.getRepairButtons();
            } else if (this.mode === 'view') {
                if (model && model.buttons) {
                    this.getViewButtons(model.buttons);
                }
            } else if (this.mode === 'spaymt') {
                this.getScheduleButtons();
            } else if (this.mode === 'restore') {
                this.getRestoreButtons();
            } else if (this.mode === 'reverse') {
                this.getReverseButtons();
            }
        }

        if (this.mode === 'insert') {
            this.getInsertButtons(saveDraftButtonSupported);
        }

        if (this.viewType !== 'modal' && !(this.buttonsToHide && util.contains(this.buttonsToHide, 'CANCEL'))) {
            this.buttonList.push({
                action: 'CANCEL',
            });
        }

        this.setNotificationData({
            title: this.contextDef.serviceName,
            state: this.mode,
        });
    },

    /**
     * @method getTheModel
     * @return {object} model
     *
     */
    getTheModel() {
        const usedContextKey = this.options.subPaymentContextKey
            || this.contextModel.getContextKey();
        return store.get(`${usedContextKey}-actionModel`);
    },

    /**
     * Get original amount value from the grid input
     * @param {Event} e - event object
     */
    getStartAmount(e) {
        $(e.currentTarget).data('start', e.currentTarget.value);
    },

    /**
     * Update footer amount total
     * @param {Event} e - event object
     */
    setNewFooterValue(e) {
        const footerVal = Formatter.stringToNumber(this.ui.$summaryAmount.first().text());
        const origValue = parseFloat($(e.currentTarget).data('start'));
        const newValue = parseFloat(e.currentTarget.value);
        const diff = (origValue - newValue) || 0;

        this.ui.$summaryAmount.text(Formatter.formatCurrency(footerVal - diff));
    },

    /**
     * HACK: since the values ENTRYDESC and COMPDISCDATA is not updating,
     * manually add an onChange to extract the value from the field
     * and update the model
     * @param e
     */
    onChange(e) {
        if (e.currentTarget.id === 'ENTRYDESC') {
            if (!util.isNullOrUndefined(this.pageView.model.get('ENTRYDESC')) || !util.isNullOrUndefined(this.pageView.model.get('COMPDISCDATA'))) {
                // if we already have the value, no need to update it
                this.pageView.model.set('ENTRYDESC', e.currentTarget.value);
                this.pageView.model.set('UE_ENTRYDESC', e.currentTarget.value);
            }
            // make sure the storedModel is accurate
            if (this.storedModel) {
                this.storedModel.set({
                    UE_ENTRYDESC: e.currentTarget.value,
                    ENTRYDESC: e.currentTarget.value,
                });
            }
        }

        if (e.currentTarget.id === 'COMPDISCDATA') {
            let newValue = e.currentTarget.value;
            let newUeValue = e.currentTarget.value;
            // if the user has erased the COMPDISCDATA value use the value
            // configured for the ACH company currently selected.
            if (newValue.length <= 0) {
                const region = this.pageView.compidnameWidgetRegion;
                if (region
                    && region.currentView
                    && region.currentView.currentSelection
                    && region.currentView.currentSelection.mapDataList) {
                    const cdd = region.currentView.currentSelection.mapDataList.filter(item => item.toField === 'CompDiscData');
                    newValue = cdd[0].value;
                    e.currentTarget.value = cdd[0].value;
                    // There is now no current user entered value.
                    newUeValue = '';
                }
            }
            // The is-not-null-or-undefined test ensures we do not do any of
            // this on models that do not possess COMPDISCDATA or
            // UE_COMPDISCKDATA members, e.g., wires.
            if (!util.isNullOrUndefined(this.pageView.model.get('COMPDISCDATA')) || !util.isNullOrUndefined(this.pageView.model.get('UE_COMPDISCDATA'))) {
                this.pageView.model.set('UE_COMPDISCDATA', newUeValue);
            }
            if (this.storedModel) {
                this.storedModel.set({
                    UE_COMPDISCDATA: newUeValue,
                    COMPDISCDATA: newValue,
                });
            }
        }
    },

    getInsertButtons(saveDraftButtonSupported) {
        this.buttonList.push({
            action: 'SAVE',
        });
        if (saveDraftButtonSupported) {
            this.buttonList.push({
                action: 'SAVEDRAFT',
            });
        }
    },

    getModifyButtons() {
        this.buttonList.push({
            action: 'SAVE',
        });
    },

    getRepairButtons() {
        this.buttonList.push({
            action: 'REPAIR',
        });
    },

    getViewButtons(buttons) {
        // buttons VIEWSUMT and REPORT are from FILE payments (File Import)
        let addMe = false;

        let button = null;
        if (buttons) {
            for (let i = 0; i < buttons.length; i += 1) {
                button = buttons[i];
                switch (button.action) {
                case 'SELECT':
                case 'GETRATE':
                case 'TRADE':
                case 'VIEWSUMT':
                case 'PRINTCHECKS':
                case 'REPRINTCHECKS':
                case 'STOPCHECK':
                case 'VOIDCHECK':
                case 'REPORT':
                    addMe = false;
                    break;
                default:
                    addMe = true;
                    break;
                }
                if (addMe) {
                    this.buttonList.push({
                        action: button.action,
                    });
                }
            }
        }
    },

    getScheduleButtons() {
        this.buttonList.push({
            action: 'SAVE',
        });
    },

    /**
     * @name getInsertPageOptions
     * @description returns options for creating a new page for insert
     */
    getInsertPageOptions() {
        return {
            context: this.contextDef,
            state: this.mode,
            hideButtons: true,
            gridApi,
            preFill: store.get(`${this.contextKey}-preFill`),
            smbOverride: this.options.smbOverride,
            reimburse: this.options.reimburse,
            comboService: this.options.comboService,
        };
    },

    renderPage() {
        const normalDisplayModes = [
            'modify',
            'padjust',
            'repair',
            'restore',
            'reverse',
            'spaymt',
            'view',
        ];
        if (this.mode === 'insert') {
            this.pageView = PageApi.page.get(this.getInsertPageOptions());
        } else if (this.mode === 'import') {
            if (store.get(`${this.contextKey}-preFill`)) {
                const actionContext = {
                    actionMode: 'SELECT',
                    entryMethod: 0,
                    functionCode: 'IMPORT',
                    productCode: 'USACH',
                };
                const context = {
                    actionContext,
                    functionCode: 'IMPORT',
                    productCode: 'USACH',
                    subType: '*',
                    serviceName: this.contextDef.serviceName,
                };
                this.pageView = PageApi.page.get({
                    context,
                    state: this.mode,
                    hideButtons: true,
                    preFill: store.get(`${this.contextKey}-preFill`),
                    gridViewOptions: {
                        paginate: true,
                        sortable: true,
                    },
                    gridApi,
                    comboService: this.options.comboService,
                });
                store.set(`${this.contextKey}-preFill`, null);
            } else {
                this.pageView = PageApi.page.get({
                    context: this.contextDef,
                    state: this.mode,
                    hideButtons: true,
                    gridViewOptions: {
                        paginate: true,
                        sortable: true,
                    },
                    gridApi,
                    comboService: this.options.comboService,
                });
            }
        } else if (this.mode === 'view' && this.options.context === 'CM_IMPORTRESULTS') {
            this.pageView = PageApi.page.get({
                context: this.contextDef,
                model: this.storedModel,
                hideButtons: true,
                state: this.mode,
                gridViewOptions: {
                    paginate: true,
                },
                gridApi,
                viewType: this.viewType,
                comboService: this.options.comboService,
            });
        } else if (normalDisplayModes.includes(this.mode)) {
            this.pageView = PageApi.page.get({
                context: this.contextDef,
                model: this.storedModel,
                hideButtons: true,
                state: this.mode,
                gridApi,
                viewType: this.viewType,
                smbOverride: this.options.smbOverride,
                reimburse: this.options.reimburse,
                bulkCheckView: this.options.bulkCheckView,
                comboService: this.options.comboService,
                auditExportInquiryId: this.options.auditExportInquiryId,
                additionalPageOptions: this.options.additionalPageOptions,
            });
        }

        this.setupListeners();
        this.pageContent.show(this.pageView);
        this.displaySummaryTotal();
    },

    onRender() {
        if (isDeepLinked()) {
            this.$el.find('.page-header-wrapper').first().hide();
            this.$el.find('.section-summary').removeClass('section-summary');
            this.$el.find('.widget-action-btn-group').removeClass('widget-action-btn-group');
        }

        if (this.hasLoadedRequiredData()) {
            this.renderPage();
        } else {
            this.loadRequiredData();
            if (this.mode === 'insert' || this.mode === 'import') {
                this.setHasLoadedRequiredData(true);
            }
        }
    },

    /**
     * @name setupListeners
     * @description sets up listeners for the pageView (entry view)
     */
    setupListeners() {
        this.listenTo(this.pageView, 'loaded', this.setPageViewListeners);
        this.listenTo(this.pageView, 'loaded', this.getPaymentMessage);
    },

    getFunctionCode() {
        let functionCode = null;
        if (this.storedModel) {
            functionCode = this.storedModel.get('FUNCTION');
            if (!functionCode && this.storedModel.jsonData && this.storedModel.typeInfo) {
                ({ functionCode } = this.storedModel.jsonData.typeInfo);
            }
        }
        if (!functionCode && this.contextDef) {
            ({ functionCode } = this.contextDef);
        }
        if (!functionCode && this.contextDef && this.contextDef.actionData) {
            ({ functionCode } = this.contextDef.actionData);
        }
        if (!functionCode && this.contextDef && this.contextDef.actionContext) {
            ({ functionCode } = this.contextDef.actionContext);
        }

        return functionCode;
    },

    getProductCode() {
        let productCode = null;
        if (this.storedModel) {
            productCode = this.storedModel.get('PRODUCT');
            if (!productCode && this.storedModel.jsonData && this.storedModel.typeInfo) {
                ({ productCode } = this.storedModel.jsonData.typeInfo);
            }
        }
        if (!productCode && this.contextDef) {
            ({ productCode } = this.contextDef);
        }
        if (!productCode && this.contextDef && this.contextDef.actionData) {
            ({ productCode } = this.contextDef.actionData);
        }
        if (!productCode && this.contextDef && this.contextDef.actionContext) {
            ({ productCode } = this.contextDef.actionContext);
        }
        if (!productCode && this.model) {
            productCode = this.model.get('PRODUCT');
        }

        return productCode;
    },

    /**
     * display the summary total line in the summary section
     */
    displaySummaryTotal() {
        const functionCode = this.targetFunction || this.getFunctionCode();
        const isTemplate = (functionCode === 'TMPL' || functionCode === 'BHTMPL' || functionCode === 'REQTMPL');
        const localeKey = (isTemplate) ? 'common.template.summary.total' : 'common.summary.total';
        const dt = Formatter.formatDateFromUserFormat(
            new Date(),
            dateUtil.PAYMENT_SUMMARY_DATE_FORMAT,
        );
        this.pageView.summaryLocaleKey = localeKey;
        const params = [{ value: '0.00', className: 'summary-large-text' }, { value: '' }, { value: '1' }, { value: locale.get('PAY.beneficiary') }, { value: dt }];
        const SummaryTotalView = createTransformLocaleView({
            localeKey,
            params,
            tagName: 'h4',
            tagClass: 'summaryTotal',
        });
        if (this.summaryTotalRegion) {
            this.summaryTotalRegion.show(new SummaryTotalView());
        }
    },

    templateHelpers() {
        const self = this;

        const isViewSMBStopCancelScreen = () => {
            let isCorrectScreen = true;
            // get the current route
            const route = window.location.pathname;
            /**
             * using these four parts of the route to uniquely identify the
             * View SMB Cancel Stop Payment Request screen
             */
            const parts = ['view', 'cm', 'smbStopCancels', 'SMBCM_STOP_LIST'];
            // if any part is missing from the route, set variable as false and break
            for (let x = 0; x < parts.length; x += 1) {
                if (route.toUpperCase().indexOf(parts[x].toUpperCase()) === -1) {
                    isCorrectScreen = false;
                    break;
                }
            }
            return isCorrectScreen;
        };

        return {

            isModal: this.viewType === 'modal',

            buttons() {
                const buttons = [];
                const buttonList = (self.storedModel && self.storedModel.get('BUTTON_ACTIONS'))
                    ? self.storedModel.get('BUTTON_ACTIONS') : self.buttonList;

                util.each(buttonList, (el, i) => {
                    const btnActionLowercase = el.action.toLowerCase();
                    const btnIsDeleteOrPrint = btnActionLowercase === 'delete' || btnActionLowercase === 'print';

                    if (isViewSMBStopCancelScreen() && btnActionLowercase === 'inquiry') {
                        return;
                    }

                    const button = {
                        btnText: locale.get(`${self.localeKey.split('.')[0]}.button_${btnActionLowercase}`),
                        btnClass: (i === 0 && !btnIsDeleteOrPrint ? 'btn-primary' : 'btn-secondary'),
                        btnAction: btnActionLowercase,
                    };

                    buttons[i] = button;
                });

                return buttons;
            },

            getButtonString(type) {
                return locale.get(`${self.localeKey.split('.')[0]}.button_${type}`);
            },

            getString(type) {
                let localeKey = self.localeKey + type;
                let title;

                /**
                 * consider the mode in setting the title so we can support
                 * "New Item" vs. "Edit Item"
                 */
                if (self.mode) {
                    localeKey = `${localeKey}-${self.mode}`;
                }

                if (localeKey === 'adminPayment_title-view' || localeKey === 'adminTemplate_title-view') {
                    const tcode = self.contextModel.get('contextDef').actionData.typeCode;
                    const fcode = self.contextModel.get('contextDef').actionData.functionCode;
                    if (fcode === 'TMPL' || fcode === 'BHTMPL') {
                        localeKey = `payment.adminTemplate.${tcode.toLowerCase()}.${fcode.toLowerCase()}`;
                    } else {
                        localeKey = `payment.adminPayment.${tcode.toLowerCase()}.${fcode.toLowerCase()}`;
                    }
                }

                title = locale.get(localeKey);

                // Start NH-15204: Override header for SMB
                if (self.options.smbOverride) {
                    if (self.options.reimburse) {
                        title = locale.get('common.employee.reimbursement');
                    } else if (self.storedModel.get('ENTRYDESC') === 'PAYROLL') {
                        // Nothing to do here
                    } else if (self.storedModel.get('BATCHTYPEDESC') === 'ACH.reimburse') {
                        title = locale.get('ACH.reimburse');
                    } else if (self.storedModel.get('ENTRYDESC') === '') {
                        title = locale.get('common.child.support');
                    }
                }
                // End NH-15204
                if (self.contextDef && self.contextDef.smbOverride
                    && self.contextDef.reimburse) {
                    title = locale.get('smbPayments.employee.reimbursement');
                }

                // Adding the Sequence number in the title for view, modify and repair modes.
                if (self.mode !== 'insert' && self.mode !== 'import') {
                    // self.storedModel
                    if (self.storedModel.get('USERGROUPSEQUENCENUMBER')) {
                        const displayId = (serverConfigParams.get('ShowTNumOnPaymentDetails') === 'true' && self.storedModel.get('TNUM')) ? self.storedModel.get('TNUM') : self.storedModel.get('USERGROUPSEQUENCENUMBER');
                        title = `${title} (${locale.get('PAY.ID')} ${displayId})`;
                    }
                }

                return title;
            },

            context() {
                return self.contextKey;
            },

            hideHeader() {
                return self.viewType === 'modal' || self.hideHeader;
            },

            hasSave() {
                return self.mode === 'insert' || self.mode === 'modify';
            },

            // temp function while converting summary line
            hideOldSummary: () => {
                const pc = this.getProductCode();
                const fc = this.getFunctionCode();
                if (!pc && !fc) {
                    return true;
                }
                return (pc === 'PAY') || (pc === 'RTGS') || (pc === 'USACH') || (pc === 'RTP' && (fc === 'INST' || fc === 'TMPL'));
            },

            hideReturnButton: () => (this.buttonsToHide ? util.contains(this.buttonsToHide, 'CANCEL') : false),
        };
    },

    // ammend the list of existing buttons if new ones are retrieved when data is loaded
    handleActionButtonsRefresh(buttonList = []) {
        const buttons = buttonList;
        let hasCancel = false;
        buttons.forEach((button) => {
            if (button.action === 'CANCEL') {
                hasCancel = true;
            }
        });
        if (this.viewType !== 'modal' && !hasCancel && !(this.buttonsToHide && util.contains(this.buttonsToHide, 'CANCEL'))) {
            buttons.push({
                action: 'CANCEL',
            });
        }
        this.storedModel.set('BUTTON_ACTIONS', buttons);
        /*
         * rather than rendering on the model set, simply hide the buttons via the DOM, this
         * is to prevent re-rendering the entire entry view (and the state that goes with it)
         * when just the buttons need to update
         */
        const buttonActionValues = util.pluck(buttons, 'action');
        const $widgetButtons = this.ui.$widgetActionBtnGroup.find('button');
        let showButtonsSelectorString = '';
        let hideButtonsSelectorString = '';
        $widgetButtons.each((idx, el) => {
            // build a selector string to show/hide all at once rather than one at a time.
            const btnText = $(el).text().toLowerCase().trim();
            const selectorText = `[name="${btnText}"],`;
            if (buttonActionValues.indexOf(btnText.toUpperCase()) === -1) {
                hideButtonsSelectorString += selectorText;
            } else {
                showButtonsSelectorString += selectorText;
            }
        });
        if (showButtonsSelectorString) {
            this.ui.$widgetActionBtnGroup.find(showButtonsSelectorString.replace(/,$/, '')).show();
        }
        if (hideButtonsSelectorString) {
            this.ui.$widgetActionBtnGroup.find(hideButtonsSelectorString.replace(/,$/, '')).hide();
        }
    },

    /**
     * @name loadRequiredDataInsertImport
     * @description - sets loadedRequiredData and renderMessage
     * this method can be overridden for additional insert
     */
    loadRequiredDataInsertImport() {
        this.setHasLoadedRequiredData(true);
        this.render();
    },

    /**
     * Update the stored tempModel with any values needed from the newly generated model
     */
    updateTempModel() {
        // Exit the function if we are missing either model
        if (!this.tempModel || !this.storedModel) {
            return;
        }

        /*
         * NH-140178 - concurrency error
         *
         * The isFromDetailScreen indicator needs to be set for File Payments.
         * File Payments include child payments, therefore there is a grid displaying
         * the child payments within the detail page of a File Payment. This means
         * that if child payment is selected from the grid for modification, during the save
         * of the child payment the user returns back to the initial File Payment detail page.
         */
        if (this.tempModel.get('TYPE') === 'FILE') {
            this.tempModel.isFromDetailScreen = true;
            /*
             * Set up a listener to handle trigger calls requesting to synchronize the update count
             * values in the model with the values returned from the responses returned for the
             * REST service payment/File/REFRESH-DETAIL-SUMMARY-TOTALS
             */
            this.listenTo(appBus, 'notifyFilePayment', this.updateCountSynchronization);
        }

        this.updateCountSynchronization();
    },

    /*
     * When the user navigates to a detail screen from another detail screen the response
     * returned from the REST read call contains the latest UPDATECOUNT__.
     * The UPDATECOUNT__ on the existing model on the screen needs to be udpated.
     */
    updateCountSynchronization() {
        if (this.tempModel.isFromDetailScreen) {
            this.tempModel.set('UPDATECOUNT__', this.storedModel.get('UPDATECOUNT__'));
        }
    },

    /*
     * loads the required data for the form, this can included configuration flags for widgets,
     * data to populate fields, etc.
     */
    loadRequiredData() {
        if (this.mode === 'insert' || this.mode === 'import') {
            this.loadRequiredDataInsertImport();
        } else {
            const usedContextKey = this.options.subPaymentContextKey
                ? this.options.subPaymentContextKey : this.contextKey;
            // todo: model passed in, why do we use the store here???
            DataApi.model.generateModelFromModel(this.model
                || store.get(`${usedContextKey}-actionModel`)).then((newModel) => {
                newModel.fetch({
                    success: () => {
                        this.storedModel = newModel;

                        this.updateTempModel();
                        this.handleActionButtonsRefresh(this.buttonList);

                        this.listenTo(this.storedModel, 'change:BUTTON_ACTIONS', (model) => {
                            this.handleActionButtonsRefresh(model.get('BUTTON_ACTIONS') || []);
                        });

                        // NH-27619 - SMB Reimburse Override
                        if (this.options.reimburse) {
                            if (this.mode === 'view' || this.mode === 'modify') {
                                this.setSmbReimburseAttributes(this.storedModel, true);
                            } else {
                                this.setSmbReimburseAttributes(this.storedModel);
                            }
                        } else {
                            this.setEntryDescription(this.storedModel);
                            this.setCompDiscData(this.storedModel);
                        }

                        this.updateForAccountMasking();

                        /* end test */
                        this.setHasLoadedRequiredData(true);
                        this.render();
                    },
                    error: util.bind(errorHandlers.loading, this),
                });
            });
        }
    },

    /**
     * @name updateForAccountMasking
     * @description  modify account number (in modify & view mode) in certain circumstances
     * for account masking.  This is for situations where in modify & view the account number is
     * read only and cannot be modified only displayed and sent back to the server
     */
    updateForAccountMasking() {
        if (systemConfig.isAdmin()) {
            return;
        }

        if (this.mode === 'view' || this.mode === 'modify') {
            const model = this.storedModel;
            if (model.get('PRODUCT') === 'CM' && ['STOP', 'CANCEL', 'CIMINSTT'].includes(model.get('TYPE'))) {
                // store the original account number in another model attributes
                model.set(
                    'ACCOUNT_NUMBER_UNMASKED', model.get('ACCOUNT_NUMBER'),
                    { silent: true },
                );
                // set the account number to what should be displayed
                model.set(
                    'ACCOUNT_NUMBER', model.get('ACCOUNT_NUMBER_DISP'),
                    { silent: true },
                );
            }
        }
    },

    /**
     * @param {object} model
     */
    prepareAccountNumberForSave(model) {
        if (model.get('ACCOUNT_NUMBER_UNMASKED')) {
            model.set(
                'ACCOUNT_NUMBER', model.get('ACCOUNT_NUMBER_UNMASKED'),
                { silent: true },
            );
        }
    },

    getFloat(input) {
        let strippedInput;
        if (typeof input === 'number') {
            return parseFloat(input);
        }

        if (input) {
            // strip out any letters to avoid unexpected formatting (e.g. 5k => 5,000)
            strippedInput = input.replace(/[A-Za-z]/g, '');
        }
        return parseFloat(number().unformat(strippedInput));
    },

    getSummaryTranTypeTotal(type) {
        const self = this;
        try {
            return self.pageView.batchChildGridView.gridView.grid.collection.models.reduce((acc, curr) => (type === curr.get('TRANTYPE') && curr.get('HOLDFLAG') !== '1' ? acc + self.getFloat(curr.get('AMOUNT')) : acc), 0);
        } catch (e) {
            return 0;
        }
    },

    getGridPaymentTotal() {
        const self = this;
        try {
            return this.pageView.batchChildGridView.gridView.grid.collection.models.reduce((acc, curr) => (curr.get('HOLDFLAG') !== '1' ? acc + self.getFloat(curr.get('AMOUNT')) : acc), 0);
        } catch (e) {
            return 0;
        }
    },

    getGridBeneficiariesCount() {
        try {
            return this.pageView.batchChildGridView.gridView.grid.collection.models.length;
        } catch (e) {
            return 0;
        }
    },

    updateBatchTotals() {
        const postData = {
            productCode: this.pageView.model.jsonData.typeInfo.productCode,
            typeCode: this.pageView.model.jsonData.typeInfo.typeCode,
            functionCode: this.pageView.model.jsonData.typeInfo.functionCode,
            subType: this.pageView.model.jsonData.subtype === undefined ? 'NACHA' : this.pageView.model.jsonData.subtype,
            entryClass: (this.pageView.model.context.actionData === undefined || this.pageView.model.context.actionData.entryClass === undefined) ? this.pageView.model.get('ENTRYCLASS') : this.pageView.model.context.actionData.entryClass,
            effectiveDate: this.pageView.model.get('EFFECTIVEDATE'),
            user: userInfo.get('id'),
            userGroup: userInfo.get('group'),
            batchSeqNumber: this.pageView.model.get('BATCHSEQNUM'),
        };
        const totalService = services.generateUrl(constants.URL_GETTOTALS);
        return http.post(totalService, postData);
    },

    setPaymentSummary(amount) {
        /**
         * Amount may optionally be passed in, e.g. when adding a new payment
         * This amount will be added to the total extracted from the grid (if present)
         * determine if there are multiple beneficiaries based on existence of child grid view
         */
        const self = this;

        if (this.pageView.model.jsonData.typeInfo.productCode !== 'RTGS') {
            this.updateBatchTotals().then((result) => {
                let total = amount || 0;
                let beneficiaries = Formatter.stringToNumber(total) ? 1 : 0;
                const tranType = $('#TRANTYPE').val();
                let creditTotal = 0;
                let debitTotal = 0;
                let creditNum = 0;
                let debitNum = 0;

                if (tranType === 'CR') {
                    creditTotal = total;
                    if (amount > 0) {
                        creditNum = 1;
                    }
                } else if (tranType === 'DB') {
                    debitTotal = total;
                    if (amount > 0) {
                        debitNum = 1;
                    }
                }

                /**
             * UpdateBatchTotals calls the server and returns the correct amounts
             * based on logic and a query to temp table if there is at least
             * one transaction and more than one beneficiary
             */
                if (Formatter.stringToNumber(result.totalTransactions) > 0
                && !!this.pageView.batchChildGridView) {
                    total = parseFloat(result.totalAmount) + parseFloat(total);
                    beneficiaries = Formatter.stringToNumber(result.totalTransactions)
                    + beneficiaries;
                    creditTotal = parseFloat(result.creditAmount) + creditTotal;
                    debitTotal = parseFloat(result.debitAmount) + debitTotal;
                    creditNum = Formatter.stringToNumber(result.creditTransactions) + creditNum;
                    debitNum = Formatter.stringToNumber(result.debitTransactions) + debitNum;
                }

                // set pageview model values
                this.pageView.model.set({
                    TOTALNUM: beneficiaries,
                    TOTALAMT: Formatter.formatCurrency(total),
                    TOTALAMTCREDIT: Formatter.formatCurrency(creditTotal),
                    TOTALAMTDEBIT: Formatter.formatCurrency(debitTotal),
                    TOTALNUMCREDIT: creditNum,
                    TOTALNUMDEBIT: debitNum,
                });

                /**
             * HACK: NH-38239 + NH-35406
             * We use the achBatch.js/getBatchTotals to update the total if
             * we have account info already
             *
             * We should probably be wrapping this entire function in the below if statement ?
             * All of this should be revisited, when we have server totals the pageview model
             * info is wrong
             */

                if (!this.pageView.model.get('ACCOUNTFILTER') || this.pageView.model.get('ACCOUNTFILTER') === '' || this.pageView.useBeneWidget) {
                    this.ui.$summaryAmount.text(Formatter.formatCurrency(total));

                    this.pageView.model.set('TOTALAMT', Formatter.formatCurrency(total));
                    //   this.ui.$summaryBeneficiaries.text((beneficiaries === 1)
                    // ? `${beneficiaries} ${locale.get('PAY.beneficiary')}` :
                    // `${beneficiaries} ${locale.get('PAY.beneficiaries')}`);// delete
                    PaymentUtil.updateACHSummaryTotal(this.pageView.model, this.pageView);
                }


                // update tran type UI values
                this.ui.$summaryCreditAmount.text(self.pageView.model.get('TOTALAMTCREDIT'));
                this.ui.$summaryDebitAmount.text(self.pageView.model.get('TOTALAMTDEBIT'));
                this.ui.$summaryCreditNumber.text(self.pageView.model.get('TOTALNUMCREDIT'));
                this.ui.$summaryDebitNumber.text(self.pageView.model.get('TOTALNUMDEBIT'));
            });
        }
    },

    /**
     * sets the batch type description
     * @param model
     */
    setSmbReimburseAttributes(model) {
        const dynamicEntryDescModes = [
            adminConstants.MODES.MODIFY,
            adminConstants.MODES.INSERT,
            adminConstants.MODES.RESTORE,
        ];
        /**
         * Only in certain modes, when the ENTRYDESC is payroll,
         * which is the default of both payroll and reimburse, should
         * the ENTRYDESC be overriden by the user entered ENTRYDESC
         * (UE_ENTRYDESC).
         * TODO Figure out how to use UE_ENTRYDESC, in tandem with the
         * backend, instead of ENTRYDESC with all this mapping back
         * and forth.
         */
        if (dynamicEntryDescModes.includes(this.mode)) {
            if (model.get('ENTRYDESC') === 'PAYROLL') {
                model.set('ENTRYDESC', model.get('UE_ENTRYDESC'));
            } else {
                model.set('UE_ENTRYDESC', model.get('ENTRYDESC'));
            }
        } else if (this.mode === 'view') {
            // always show user entered entry description in view mode
            model.set('ENTRYDESC', model.get('UE_ENTRYDESC'));
        }
        model.set('BATCHTYPEDESC', 'ACH.reimburse');
        model.set('CMB_TYPE_DESCRIPTION', 'ACH.reimburse');
        model.set('TYPE_DESCRIPTION', 'ACH.reimburse');
    },

    /**
     * sets the ENTRYDESC field
     * @param {Object} model
     */
    setEntryDescription(model) {
        if (util.isNullOrUndefined(model)) {
            return;
        }

        const ueEntryDesc = model.get('UE_ENTRYDESC');
        const entryDesc = model.get('ENTRYDESC');
        if (this.mode === 'view') {
            // NH-56415 On view, entry description can be an empty string
            if (!util.isNullOrUndefined(ueEntryDesc)) {
                model.set('ENTRYDESC', ueEntryDesc);
            }
        } else if (this.mode === 'insert') {
            model.set('UE_ENTRYDESC', entryDesc);
        } else if (this.mode === 'modify') {
            model.set('ENTRYDESC', ueEntryDesc);
        }
    },

    /**
     * sets the COMPDISCDATA field. User may override the value configured
     * for the ACH company.
     * @param {Object} model
     */
    setCompDiscData(model) {
        if (util.isNullOrUndefined(model)) {
            return;
        }

        const ueCompDiscData = model.get('UE_COMPDISCDATA');
        if (this.mode === 'view' || this.mode === 'insert' || this.mode === 'modify') {
            if (ueCompDiscData && ueCompDiscData.length > 0) {
                model.set('COMPDISCDATA', ueCompDiscData);
            }
        }
    },

    setPageViewModelListeners() {
        this.listenTo(this.pageView.model, 'invalid', this.enableButtons);
        this.listenTo(this.pageView.model, 'modelAction:saveWithWarning', this.saveWithWarning);
        this.listenTo(this.pageView.model, 'modelAction:cancelWarning', this.cancelWarning);
        this.listenTo(this.pageView.model, 'modelAction:approveWithWarning', this.approveWithWarning);
        this.listenTo(this.pageView.model, 'modelAction:repairWithWarning', this.repairWithWarning);
        this.listenTo(this.pageView.model, 'modelAction:acceptDisclosure', this.acceptDisclosure);
        this.listenTo(this.pageView.model, 'modelAction:success', this.actionSuccess);
        this.listenTo(this.pageView.model, 'modelAction:errorForDetail', this.actionErrorForDetail);
        this.listenTo(this.pageView.model, 'modelAction:deleteWithWarning', this.deleteWithWarning);
    },

    setPageViewListeners() {
        this.listenTo(this.pageView, 'modelAction:newModel', this.setPageViewModelListeners);
        this.listenTo(this.pageView, 'modelAction:success', this.actionSuccess);
        this.listenTo(this.pageView, 'modelAction:error', this.actionError);
        this.listenTo(this.pageView, 'invalid', this.enableButtons);
        this.listenTo(this.pageView, 'removeAlert', this.removeAlert);

        this.setPageViewModelListeners();

        if (this.tempModel) {
            this.listenTo(this.tempModel, 'modelAction:approveWithWarning', this.approveWithWarning);
            this.listenTo(this.tempModel, 'modelAction:acceptDisclosure', this.acceptDisclosure);
            this.listenTo(this.tempModel, 'modelAction:deleteWithWarning', this.deleteWithWarning);
            this.listenTo(this.tempModel, 'modelAction:cancelDisclosure', this.enableButtons);
        }

        this.listenTo(this.pageView, 'ui-loaded', () => {
            if (this.pageView.childView) {
                if (this.pageView.childView.ui.$amount
                    && this.pageView.childView.ui.$amount.length > 0) {
                    this.setChildViewListeners();
                } else {
                    this.listenTo(this.pageView.childView, 'render', () => {
                        this.setChildViewListeners();
                    });
                }
            }
            if (this.pageView.batchChildGridView
                && this.pageView.batchChildGridView.gridView) {
                this.setBatchChildGridViewListeners();
            } else {
                this.listenTo(this.appBus, 'all', (event) => {
                    if (event.indexOf('gridapi:showMessages') !== -1) {
                        if (this.pageView.batchChildGridView
                            && this.pageView.batchChildGridView.gridView) {
                            this.setBatchChildGridViewListeners();

                            /*
                             * HACK: We shouldn't stop listening to the appbus here,
                             * that is the Hack, but until
                             * We can fix it, we need to at least re-enable submit,
                             * because we're now deaf to
                             * validation changes, which means it won't otherwise be
                             * re-enabled on change.
                             */
                            this.enableButtons();
                            this.stopListening(this.appBus, 'all');
                        }
                    }
                });
            }
        });
    },

    getPaymentMessage() {
        PaymentUtil.getPaymentMessage(this.pageView.model.jsonData, this);
    },

    setBatchChildGridViewListeners() {
        const self = this;
        const { gridView } = this.pageView.batchChildGridView;

        /**
         * set grid view listeners after grid is loaded
         * (linking parts of view to react to actions made in grid)
         */
        this.listenTo(gridView, 'gridapi:loaded', () => {
            self.listenTo(
                gridView.wrapper.rows,
                'change:AMOUNT',
                () => {
                    self.setPaymentSummary();
                },
            );
            // Get totals when we update amounts and save
            self.listenTo(self.appBus, 'nachaChild:update:success', self.setPaymentSummary);
            // Get totals when we update the hold and save
            self.listenTo(self.appBus, 'nachaChild:hold:update:success', self.setPaymentSummary);
        });
    },

    setChildViewListeners() {
        const self = this;
        let { $amount } = this.pageView.childView.ui;
        const $amountFields = this.$el.find('[data-type="amount"]').not('[name="TEMPLATE_MAX_AMOUNT"],[name="SECOND_ACCOUNT_ALLOCATION"]');
        const $cvAmount = self.pageView.childView.ui.$amount;
        // If it doesn't exist (probably due to beneWidget) go find it.
        $amount = (typeof $amount === 'string' || $amount.length === 0) ? $amountFields : $amount;
        this.amountField = $amount;

        $amount.on('change', (evt) => {
            let amount = 0;
            if (!$('[name="HOLDFLAG"]').prop('checked')) {
                amount = self.getFloat(evt.target.value) || 0;
            }
            self.setPaymentSummary(amount);
        });

        $('#TRANTYPE').on('change', () => {
            const amount = self.getFloat($cvAmount.val()) || self.getFloat($amountFields.val());
            self.setPaymentSummary(amount);
        });

        $('#HOLDFLAG').on('change', () => {
            let amount = 0;
            if (!$('[name="HOLDFLAG"]').prop('checked')) {
                amount = self.getFloat($cvAmount.val()) || self.getFloat($amountFields.val());
            }
            self.setPaymentSummary(amount);
        });

        $('#PRENOTEFLAG').on('change', () => {
            if ($('[name="PRENOTEFLAG"]').prop('checked')) {
                self.setPaymentSummary(0);
            }
        });

        $('#ZERODOLLARLIVE').on('change', () => {
            if ($('[name="ZERODOLLARLIVE"]').prop('checked')) {
                self.setPaymentSummary(0);
            }
        });

        // Add appBus listener so we can support MDF
        this.stopListening(this.appBus, 'updatePaymentSummary');
        this.listenTo(this.appBus, 'updatePaymentSummary', (amount) => {
            /*
             * Types that use the been widget could have an amount entered on the screen that
             * needs to be passed in and added to what is already in the grid to get the total.
             * Types that do not use the bene widget have the user enter new bene's in a
             * seperate modal, so it is not necessary to pass in an additional amount.
             */
            const currentNonGridAmount = self.pageView.useBeneWidget ? (amount
                || (self.amountField[0] ? self.amountField[0].value : undefined)) : undefined;
            self.setPaymentSummary(currentNonGridAmount);
        });
    },

    repairWithWarning(model, options) {
        let warningName = '_saveWithWarning';
        if (options && options.warningName) {
            ({ warningName } = options);
        }

        if (model.get(warningName) !== 'true') {
            this.disableButtons();

            model.set(warningName, 'true');
            this.pageView.repair();
        }
    },

    saveWithWarning(model, options) {
        let warningName = '_saveWithWarning';
        if (options && options.warningName) {
            ({ warningName } = options);
        }
        if (model.get(warningName) !== 'true') {
            if (options && options.isFromFooter) {
                this.footerForm.disableButtons();
            } else {
                this.disableButtons();
            }

            if (this.options.reimburse) {
                this.setSmbReimburseAttributes(model, true);
            }

            model.set(warningName, 'true');

            if (options && options.isFromFooter) {
                this.footerForm.processSubmission();
            } else {
                this.pageView.save();
            }
        }
    },

    cancelWarning(model) {
        this.disableButtons();

        if (this.options.reimburse) {
            this.setSmbReimburseAttributes(model, true);
        }

        model.set({
            _cancelFromWarning: 'true',
        });
        this.pageView.save();
    },

    acceptDisclosure() {
        this.disableButtons();
        if (!this.tempModel && this.pageView.model) {
            this.tempModel = this.pageView.model;
        } else if (this.tempModel && this.pageView.model) {
            if (this.pageView.model.get('CONSUMER_DISCLOSURE_USER_ACCEPTED')) {
                this.tempModel.set('CONSUMER_DISCLOSURE_USER_ACCEPTED', this.pageView.model.get('CONSUMER_DISCLOSURE_USER_ACCEPTED'));
            }
            if (this.pageView.model.get('CONSUMER_DISCLOSURE_USER_REJECTED')) {
                this.tempModel.set('CONSUMER_DISCLOSURE_USER_REJECTED', this.pageView.model.get('CONSUMER_DISCLOSURE_USER_REJECTED'));
            }
            if (this.pageView.model.get('CONSUMER_DISCLOSURE_USER_REJECTED')) {
                this.tempModel.set('UPDATECOUNT__', this.pageView.model.get('UPDATECOUNT__'));
            }
        }

        if (this.tempModel.get('ENTRYMETHOD') === '1' && this.tempModel.get('MAX_SIGNATURES_OVERRIDE') === '0' && (this.tempModel.get('DISCLOSURE_ACTION_MODE') === 'INSERT' || this.tempModel.get('DISCLOSURE_ACTION_MODE') === 'MODIFY')) {
            this.save();
        } else {
            this.approve();
        }
    },

    approveWithWarning(options) {
        if (options && options.isFromFooter) {
            this.footerForm.disableButtons();
        } else {
            this.disableButtons();
        }

        if (!this.tempModel && this.pageView.model) {
            this.tempModel = this.pageView.model;
        }

        if (options && options.isFromFooter) {
            this.footerForm.processSubmission();
        } else {
            if (options && options.warningName) {
                this.tempModel.set(options.warningName, 'true');
            } else {
                this.tempModel.set({
                    _saveWithWarning: 'true',
                });
            }

            /*
             * The stored model is passed to the MetaDrivenForm. If a chained action like
             * APPROVE has, on the detail page, encountered a warning condition it is
             * possible that these will be out of sync.  The storedModel will have the
             * current value.  Without this the approval will fail with an "unable to
             * load instruction model" error.
             */
            if (this.storedModel
                && this.storedModel.get('UPDATECOUNT__')) {
                this.tempModel.set('UPDATECOUNT__', this.storedModel.get('UPDATECOUNT__'));
            }

            this.approve();
        }
    },

    /**
     * Button Action - occurs when associated 'Save' footer button is clicked
     * Need to check model to make sure that EntryDesc field is set before saving.
     */
    save() {
        /*
         * If user somehow manages to trigger a second save event during another
         * save action, we will ignore the new action.
         */
        if (this.pageView.isSaveAction) {
            return;
        }
        // in some instances we need to update account number
        this.prepareAccountNumberForSave(this.pageView.model);

        this.pageView.isSaveAction = true;
        this.disableButtons();
        if (this.options.reimburse) {
            this.setSmbReimburseAttributes(this.pageView.model, true);
        } else {
            this.setEntryDescription(this.pageView.model);
            this.setCompDiscData(this.pageView.model);
        }
        this.pageView.model.unset('_saveIncomplete');
        this.pageView.save();

        let contextKeyToUse = this.contextKey;
        if (this.options.subPaymentContextKey) {
            contextKeyToUse = this.options.subPaymentContextKey;
        }
        store.remove(`${contextKeyToUse}-actionModel`);
    },

    /**
     * Button Action - occurs when associated 'Repair' footer button is clicked
     */
    repair() {
        this.disableButtons();
        this.pageView.repair();
    },

    /*
    * reenable all manually disabled validators
    * @param {array} changedValidators - an array of validator objects that have been disabled
    */
    reenableValidators(changedValidators) {
        util.each(changedValidators, (validator) => {
            const validatorParam = validator;
            validatorParam.disabled = false;
        });
    },

    /*
    * Disables validators, keeps track of the validators it disables,
    * and reenables them upon submission failure
    * @param {view} view - the correct scope containing the model with validators to disable
    */
    disableValidators(view) {
        if (!view.model) {
            return;
        }
        const changedValidators = util.chain(view.model.validators)
            .filter(validator => !(validator.disabled))
            .each((validator) => {
                const validatorParam = validator;
                validatorParam.disabled = true;
            })
            .value();

        /*
        * HACK: NH-35490
        * originator or debit account info is required prior to saving as draft,
        * so ensure those validators remain active
        */
        if (view.model.validators.COMPIDNAME) {
            // eslint-disable-next-line no-param-reassign
            view.model.validators.COMPIDNAME.disabled = false;
        }
        if (view.model.validators.DEBIT_ACCOUNT_NUMBER) {
            // eslint-disable-next-line no-param-reassign
            view.model.validators.DEBIT_ACCOUNT_NUMBER.disabled = false;
        }
        if (view.model.validators.VALUE_DATE) {
            // eslint-disable-next-line no-param-reassign
            view.model.validators.VALUE_DATE.disabled = false;
        }
        if (view.model.validators.ORIGINATOR_ID) {
            // eslint-disable-next-line no-param-reassign
            view.model.validators.ORIGINATOR_ID.disabled = false;
        }
        if (view.model.validators.ACCOUNTFILTER) {
            // eslint-disable-next-line no-param-reassign
            view.model.validators.ACCOUNTFILTER.disabled = false;
        }
        if (view.model.validators.EFFECTIVEDATE) {
            // eslint-disable-next-line no-param-reassign
            view.model.validators.EFFECTIVEDATE.disabled = false;
        }

        // if the model or view is invalid, re-enable the validators appropriately

        view.listenToOnce(view, 'modelAction:error', () => {
            this.reenableValidators(changedValidators);
            this.enableButtons();
        });

        view.listenToOnce(view.model, 'invalid', () => {
            this.reenableValidators(changedValidators);
            this.enableButtons();
        });

        // if this is a child view, re-enable validators when the parent does.
        if (view.parentView) {
            view.listenToOnce(view.parentView, 'modelAction:error', () => {
                this.reenableValidators(changedValidators);
                this.enableButtons();
            });
            view.listenToOnce(view.parentView.model, 'invalid', () => {
                this.reenableValidators(changedValidators);
                this.enableButtons();
            });
        }
    },


    /**
     * Button Action - occurs when associated 'Save For Later' footer button is clicked
     * Need to check model to make sure that EntryDesc field is set before saving.
     */
    savedraft() {
        this.disableButtons();
        this.pageView.model.set({
            _saveIncomplete: 'true',
        });
        if (this.options.reimburse) {
            this.setSmbReimburseAttributes(this.pageView.model, true);
        } else {
            this.setEntryDescription(this.pageView.model);
            this.setCompDiscData(this.pageView.model);
        }
        this.pageView.save();
    },

    /**
     * Button Action - occurs when associated 'Cancel' footer button is clicked
     */
    cancel() {
        this.returnToListView(true);
    },

    /**
     * Button Action - occurs when associated 'Pay' footer button is clicked
     */
    pay() {
        this.createFooterForm(this.createFooterFormConfigurationObject('PAY'));
    },

    /**
     * Button Action - occurs when associated 'Authorize' footer button is clicked
     */
    authrize() {
        this.createFooterForm(this.createFooterFormConfigurationObject('AUTHRIZE'));
    },

    /**
     * Button Action - occurs when associated 'Reject' footer button is clicked
     */
    rejectfm() {
        this.createFooterForm(this.createFooterFormConfigurationObject('REJECTFM'));
    },

    /**
     * Button Action - cancel request, from footer button in RFP outgoing
     */
    canclreq() {
        this.createFooterForm(this.createFooterFormConfigurationObject('CANCLREQ', constants.URL_RTP_SUBMITTED));
    },

    createFooterFormConfigurationObject(actionSubType, useThisService) {
        const serviceName = useThisService || `/${this.contextDef.serviceName.split('/')[1]}/${this.contextDef.actionContext.typeCode.toLowerCase()}`;

        return {
            context: {
                actionContext: { ...this.contextDef.actionContext, subType: actionSubType },
                actionMode: this.contextDef.actionMode,
                serviceName,
            },
            hideButtons: true,
            state: 'SELECT',
            footerForm: true,
            gridApi,
        };
    },

    /**
     * give the footer form its model, render it, and add relevant listeners
     * @param newModel {Object} - the model for the footer form
     * @param configurationObject {Object} - the configuration object used to create the
     * footer form
     */
    renderFooterFormAndAddListeners(newModel, configurationObject) {
        // set the footer form model
        this.footerForm.model = newModel;

        this.footerForm.setHasLoadedRequiredData(true);
        this.listenTo(this.footerForm, 'loaded', this.setFooterViewListeners);
        this.footerForm.setupViewRequirements(configurationObject);
    },

    /**
     * Once the footer form has loaded, add any needed listeners to the view and show it
     */
    setFooterViewListeners() {
        this.footerFormRegion.show(this.footerForm);

        this.footerForm.listenTo(this.footerForm.model, 'change', this.footerForm.handleModelChangeEvents);
        this.listenTo(this.footerForm, 'cancelFooterForm', this.toggleFooterForm);
        this.listenTo(this.footerForm, 'modelAction:success', this.actionSuccess);
        this.listenTo(this.footerForm, 'modelAction:error', this.actionError);

        this.toggleFooterForm(true);
        this.setFooterModelListeners();
    },

    /**
     * Create a footer MDF form that will take the place of the normal summaries/buttons
     * @param configurationObject {Object} - the configuration object used to create the
     * footer form
     */
    createFooterForm(configurationObject) {
        const self = this;
        const typeOfNewFooterForm = configurationObject.context.actionContext.subType;
        // if this is the first footer form, treat it as current
        const typeOfCurrentFooterForm = this.footerForm
            ? this.footerForm.context.actionContext.subType : typeOfNewFooterForm;

        // if this is a different footer form than the one currently loaded
        if (this.footerForm && (typeOfNewFooterForm !== typeOfCurrentFooterForm)) {
            // remove listeners to current footer form and set it to undefined
            this.stopListening(this.footerForm);
            this.stopListening(this.footerForm.model);
            this.footerForm = undefined;
        }

        // only create the form/add listeners to a footer form if it doesn't already exist
        if (!this.footerForm) {
            this.footerForm = PageApi.page.get({
                pageView: this.pageView,
                ...configurationObject,
            });

            viewHelper.getModel(configurationObject).then((newModel) => {
                const pageFormContext = this.pageView.options.context.actionContext;
                const footerFormContext = this.footerForm.options.context.actionContext;

                /*
                 * When creating the model for a footer form, if the product, type, and function
                 * codes are all the same as the page form, then we've already got the data
                 * and can transfer it directly to the footer form.
                 * If these codes AREN'T the same, then we need to do a seperate "read" call to
                 * populate the model with form data.
                 */
                if (pageFormContext.typeCode === footerFormContext.typeCode
                    && pageFormContext.functionCode === footerFormContext.functionCode
                    && pageFormContext.productCode === footerFormContext.productCode) {
                    // get data from the page form rather than a network call
                    newModel.set(this.pageView.model.attributes);
                    self.renderFooterFormAndAddListeners(newModel, configurationObject);
                } else {
                    newModel.fetch({
                        success() {
                            self.renderFooterFormAndAddListeners(newModel, configurationObject);
                        },
                        error: util.bind(errorHandlers.loading, self),
                    });
                }
            });
        } else {
            this.toggleFooterForm(false);
        }
    },

    /**
     * Toggle the visibility of the footer form and other footer elements
     * @param firstTime {Boolean} - indicates the first time the footer form has been opened
     */
    toggleFooterForm(firstTime) {
        this.footerFormRegion.$el.toggleClass('hidden');
        this.ui.$footerElements.toggleClass('hidden');
        this.footerForm.reset(firstTime);
    },

    /**
    * Adds the listeners for the "WithWarning" events so decisions users make in dialogs
    * presented by the footer form will be propagated to entry.
    */
    setFooterModelListeners() {
        this.listenTo(this.footerForm.model, 'modelAction:approveWithWarning', this.approveWithWarning);
        this.listenTo(this.footerForm.model, 'modelAction:saveWithWarning', this.saveWithWarning);
    },

    /**
     * @method returnToListView
     *
     * @param {boolean}[scrollY] - indicates whether to use the original scrollY value to
     * position the user back on to the returning workspace at the initial positio from which
     * the user came from
     */
    returnToListView(scrollY) {
        const contextKeyToUse = this.options.subPaymentContextKey || this.contextKey;
        this.disableButtons();
        workspaceHelper.returnToCurrentWorkspace(this, !scrollY);

        /**
         * When canceling from a screen that was navigated to from another detail screen, the
         * user may be going back to the previous screen, and not the list view. So the model
         * may still be needed.
        */
        if (!this.tempModel || !this.tempModel.isFromDetailScreen) {
            store.remove(`${contextKeyToUse}-actionModel`);
        }
    },

    /**
     * @method deleteWithWarning
     * - The method is called as when user clicks OK button on a warning dialog for delete
     * - This method sets the _saveWithWarning value in model to true.
     * - This method calls the model's destroy method.
     */
    deleteWithWarning() {
        const self = this;
        this.disableButtons();
        let { contextKey } = self;
        if (self.options.subPaymentContextKey) {
            contextKey = self.options.subPaymentContextKey;
        }
        if (!this.tempModel && this.pageView.model) {
            this.tempModel = this.pageView.model;
        }
        this.tempModel.set({
            _saveWithWarning: 'true',
        });
        this.tempModel.destroy({
            success(model, resp) {
                store.set(`${contextKey}-alertMessage`, 'DELETE');
                store.set(`${contextKey}-confirms`, resp);
                workspaceHelper.returnToCurrentWorkspaceOnResponse(self);
            },
            error() {
                store.set(`${contextKey}-alertMessage`, 'DELETE error');
                workspaceHelper.returnToCurrentWorkspaceOnResponse(self);
            },
        });
    },

    delete() {
        const self = this;
        let { contextKey } = self;
        if (self.options.subPaymentContextKey) {
            contextKey = self.options.subPaymentContextKey;
        }
        dialog.confirm(locale.get('tableMaintenance.dialog.confirm.item.delete'), locale.get('tableMaintenance.dialog.confirm.title.delete'), (ok) => {
            if (ok) {
                self.tempModel.destroy({
                    success(model, resp) {
                        if (resp.resultType === 'WARNING') {
                            dialog.custom(new WarningDialog({
                                model: self.tempModel,
                                methodName: 'DELETE',
                                store,
                                detailPage: self,
                                confirms: resp.confirms,
                            }));
                        } else {
                            store.set(`${contextKey}-alertMessage`, 'DELETE');
                            store.set(`${contextKey}-confirms`, resp);
                            workspaceHelper.returnToCurrentWorkspaceOnResponse(self);
                        }
                    },
                    error() {
                        store.set(`${contextKey}-alertMessage`, 'DELETE error');
                        workspaceHelper.returnToCurrentWorkspaceOnResponse(self);
                    },
                });
            }
        });
    },

    approve() {
        const self = this;
        let { contextKey } = self;
        if (self.options.subPaymentContextKey) {
            contextKey = self.options.subPaymentContextKey;
        }

        if (self.storedModel && self.storedModel.attributes
            && self.storedModel.get('INITIATEPREFUND') === '1'
            && !self.prefundingWarningAccepted) {
            self.showPrefundingWarning();
        } else {
            this.tempModel.approve({
                success(model, resp) {
                    if (resp.resultType === 'WARNING') {
                        if (resp.errorCode === 514) {
                            self.viewDisclosure(self.tempModel);
                        } else if (resp.errorCode === 540) {
                            dialog.custom(new DuplicateDialog({
                                model,
                                resp,
                                methodName: 'APPROVE',
                            }));
                        } else if (resp.errorCode === constants.BENE_UPDATED_ERROR_CODE) {
                            dialog.custom(new BeneUpdatedWarning({
                                model,
                                resp,
                                isMultiApprove: false,
                            }));
                        } else {
                            dialog.custom(new WarningDialog({
                                model: self.tempModel,
                                methodName: 'APPROVE',
                                store,
                                detailPage: self,
                                confirms: resp.confirms,
                            }));
                        }
                    } else {
                        store.set(`${contextKey}-alertMessage`, 'APPROVE');
                        store.set(`${contextKey}-confirms`, resp);
                        workspaceHelper.returnToCurrentWorkspaceOnResponse(self);
                    }
                },
                error(model, resp) {
                    if (resp.resultType === 'WARNING') {
                        dialog.confirm(
                            resp.confirms.confirmResults[0].messages[0],
                            resp.message[0], (ok) => {
                                if (ok) {
                                    self.trigger('modelAction:approveWithWarning', self.model);
                                }
                            },
                        );
                    } else {
                        store.set(`${contextKey}-alertMessage`, 'APPROVE error');
                    }
                },
            });
        }
    },

    reject() {
        const self = this;
        dialog.custom(new RejectDialog({
            model: this.tempModel,
            store,
            detailPage: self,
        }));
    },

    needinfo() {
        const self = this;
        dialog.custom(new NeedInfoDialog({
            model: this.tempModel,
            store,
            detailPage: self,
        }));
    },

    unapprov() {
        const self = this;
        let { contextKey } = self;
        if (self.options.subPaymentContextKey) {
            contextKey = self.options.subPaymentContextKey;
        }
        this.tempModel.unapprove({
            success(model, resp) {
                store.set(`${contextKey}-alertMessage`, 'UNAPPROVE');
                store.set(`${contextKey}-confirms`, resp);
                workspaceHelper.returnToCurrentWorkspaceOnResponse(self);
            },
            error() {
                store.set(`${contextKey}-alertMessage`, 'UNAPPROVE error');
            },
        });
    },

    showPrefundingWarning() {
        dialog.confirm(locale.get('common.prefund.initiate'), 'Warning', (ok) => {
            if (ok) {
                this.prefundingWarningAccepted = true;
                this.approve();
            }
        });
    },

    viewDisclosure(model) {
        const printOptions = {
            tnum: model.get('TNUM'),
            outputFormat: 'PDF',
            pageType: 'LETTER',
            viewId: 'WIREINTLDISCLOSURE',
            serviceCode: 'WIREINTLDISCLOSURE',
            dynamicReportURL: `${ReportConstants.WIRE_DISCLOSURE_PREFIX}reportView`,
            isApprovalDisclosure: true,
            approvalDisclosureModel: model,
            approvalDisclosureGrid: this.gridView,
            asyncReport: false,
        };
        DynamicReportUtil.print(printOptions);
    },

    /**
     * @method cancelstop
     * The method is called as part of the meta driven page when user clicks on
     * Cancel Stop button. and the method name is derived based on the action so it cannot
     * be cancelStop (camel case).
     * This method handles the cancelStop action from the details page.
     * This action is only applicable to a View of Stop Payment record from stop
     * payment list view.
     * The use of cache is unavoidable as the MDF expects the model to be available in
     * the cache for using in the detail page. just like all the methods in this file
     * This method overrides the context so that the cancelStop is used instead of placeStop.
     */
    cancelstop() {
        const overrideContext = util.extend(this.contextDef, {
            serviceName: '/cminst/cancelStop',
        });
        store.set(`${this.contextKey}-contextOverride`, overrideContext);
        store.set(`${this.contextKey}-actionModel`, this.tempModel);
        if (userInfo.isSmbUser() && this.contextKey === 'cm_listView_stopCancels') {
            this.navigateTo('/cminst/insert/cm/SMB_CM_STOP_LIST');
        } else {
            this.navigateTo('/cminst/insert/cm/PAY_CM_IV_LIST');
        }
    },

    modify() {
        store.set(`${this.contextKey}-actionModel`, this.tempModel);
        if (userInfo.isSmbUser() && this.contextKey === 'cm_smbStopCancels') {
            this.navigateTo('/undefined/modify/cm/smbStopCancels/SMBCM_STOP_LIST');
        } else {
            this.navigateTo(this.getModifyUrl());
        }
    },

    /**
     * @method getModifyUrl
     * @returns {*} URL - route to navigate to for modify
     */
    getModifyUrl() {
        return this.contextModel.getModifyUrl();
    },

    actionSuccess(confirmResponse) {
        this.setConfirmAndWarningMessage(confirmResponse);
        workspaceHelper.returnToCurrentWorkspaceOnResponse(this);
    },

    /**
     * sets the confirm and warning message in the global store
     * @param {Object} confirmResponse
     */
    setConfirmAndWarningMessage(confirmResponse) {
        let alertMessage;
        let { contextKey } = this;

        // If returning to a tabbed widget make sure the right tab is in focus on return
        if (this.contextDef.returnTabStoreName) {
            store.set(this.contextDef.returnTabStoreName, this.contextDef.returnTabStoreValue);
        }

        if (this.options.subPaymentContextKey) {
            contextKey = this.options.subPaymentContextKey;
        }

        if (this.contextDef.keyOverride) {
            contextKey = this.contextDef.keyOverride;
        }

        // if returning to an accordion widget, then make sure the correct accordion is opened
        if (this.contextDef.returnAccordionId) {
            store.set('returnAccordionId', this.contextDef.returnAccordionId);
        }

        if (confirmResponse.resultType === 'WARNING') {
            alertMessage = {
                message: confirmResponse.confirms.confirmResults[0].messages[0],
                type: confirmResponse.resultType,
            };
            store.set('template_listView_corp-alertMessage', alertMessage);
        } else if (confirmResponse.resultType === 'DELEGATERESULT') {
            alertMessage = {
                message: confirmResponse.confirms.confirmResults[0].messages,
                type: confirmResponse.resultType,
            };
            store.set(`${this.contextKey}-alertMessage`, alertMessage);
        } else {
            if (this.options.reimburse) {
                const paymentType = util.findWhere(
                    confirmResponse.confirms.confirmResults[0].confirmData[0].item,
                    {
                        name: locale.get('ACH.PaymentType:'),
                    },
                );
                if (paymentType) {
                    paymentType.value = locale.get('ACH.reimburse');
                }
            }
            store.set(`${contextKey}-alertMessage`, this.mode.toUpperCase());
            store.set(`${contextKey}-confirms`, confirmResponse);
        }
    },

    actionError(model) {
        this.renderMessage(model.error);
        this.enableButtons();

        this.alertRegion.$el.hide();
        $('html, body').animate({
            scrollTop: 0,
        }, 300);
        this.alertRegion.$el.fadeIn(600, () => this.alertView.$el.focus());
    },

    /**
     * @description Function that checks for the presence of alert box
     * which occurs when user enters an invalid template code while creating
     * a new Cash Concentration/Disbursement Template
     * and then delete the alert when user is able to successfully add a new beneficiary
     * post a valid template code has been entered
     */
    removeAlert() {
        this.alertRegion.reset();
    },

    actionErrorForDetail(model) {
        this.renderMessage(model.error);
        this.enableButtons();
    },

    getListUrl() {
        const r = store.get(`${this.contextKey}-listRoute`);
        return r || this.contextModel.getListUrl();
    },

    /**
     * @description Calls setDisabled which will either enable or disable the buttons
     * passed in based on the second boolean paramater
     * true = disable, and false = enable
     */
    disableButtons() {
        domUtil.setDisabled(
            [this.ui.$saveButton, this.ui.$saveDraftButton, this.ui.$cancelButton],
            true,
        );
    },

    /**
     * @description Calls setDisabled which will either enable or disable the buttons
     * passed in based on the second boolean paramater
     * true = disable, and false = enable
     */
    enableButtons() {
        // If the buttons need to be re-enabled for any reason then we know
        // we are no longer in a valid save action.
        this.pageView.isSaveAction = false;
        domUtil.setDisabled(
            [
                this.ui.$saveButton,
                this.ui.$saveDraftButton,
                this.ui.$cancelButton,
                this.ui.$reverseButton,
            ],
            false,
        );
    },

    renderMessage(error) {
        let message;
        let msgArr;
        let single = false;
        let hasMessageContent;
        let hasWarning;
        let alertType = alert.danger;

        if (error === null || error === undefined) {
            return;
        }
        // remove rouge app resource key
        message = util.without(error.message, 'tablemaint.process.action.warning').join(' ');
        if (error.confirms && error.confirms.confirmResults) {
            const errorParam = error;
            errorParam.confirms.confirmResults[0].confirmData = null;
        }

        if (error.confirms && error.confirms.confirmResults
            && error.confirms.confirmResults.length === 1
            && error.confirms.confirmResults[0].messages
            && error.confirms.confirmResults[0].messages.length === 1) {
            [message] = error.confirms.confirmResults[0].messages;
            hasMessageContent = typeof message === 'object' && util.has(message, 'messageContent');
            if (hasMessageContent) {
                alertType = message.messageType
                === constants.messageType.warning ? alert.warning : alert.info;
            }
            message = hasMessageContent ? message.messageContent : message;
            single = true;
        } else if (error.confirms && error.confirms.totalFail === 0
                   && error.confirms.totalSuccess > 0) {
            alertType = alert.success;
        }

        if (single) {
            this.alertView = alertType(message, {
                canDismiss: true,
            });
        } else {
            /**
             * warning messages should render as warning if the custom query has
             * indicated as such
             */
            if (error.confirms && error.confirms.confirmResults
                && error.confirms.confirmResults.length === 1
                && util.isArray(error.confirms.confirmResults[0].messages)
                && error.confirms.confirmResults[0].messages.length > 0
                && util.has(error.confirms.confirmResults[0].messages[0], 'messageContent')) {
                msgArr = error.confirms.confirmResults[0].messages;

                // if any of the messages is a warning message, alert type should be warning
                hasWarning = util.chain(msgArr).pluck('messageType').contains(constants.messageType.warning).value();
                alertType = hasWarning ? alert.warning : alert.info;
            }

            const confirms = new Confirms({
                confirms: error.confirms,
            });

            // display notification message
            this.alertView = alertType(message, {
                details: confirms,
                canDismiss: true,
            });
        }

        this.alertRegion.show(this.alertView);
    },
});
